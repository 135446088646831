.filial-button
  background-color: #ffe8da
  padding: 0.25rem 2rem
  border-radius: .5rem
.cart-order-section
  background: white
.cart
  @media #{$W768}
    +flex-parent(100%, row wrap, center, center)

.cart-title
  +flex-parent(100%, row wrap, flex-start, center)
  color: #511826
  @media #{$W575}
    [class*="col-"]
      padding-left: 0
  h1
    font: 2.5rem $cursive
  @media #{$W768}
    [class*="col-"]:last-child
      display: none

.cart-content
  color: #626262
  padding-bottom: 1rem
  .btn-primary
    border-width: 0
    color: $color--chocolate-brown
    font-size: 1rem
    padding: 0.25rem 0.5rem
  &:last-child
    padding-bottom: 0
  [class*="col-"]
    +flex-parent(100%, row wrap, flex-start, center)
    // height: 5rem
    overflow: initial
    @media #{$W575}
      height: auto
    .form-control
      display: inline-block
      width: 4rem
      border-radius: 1rem
    .select-form-control
      width: 10rem
    &:first-child
      +flex-parent(100%, row nowrap, center, center)
      img
        width: 75%
        height: 100%
        object-fit: cover
        overflow: hidden
      @media #{$W575}
        height: 10rem
    &:nth-child(2)
      +flex-parent(100%, column nowrap, center, flex-start)
      @media #{$W575}
        +flex-parent(100%, column nowrap, flex-start, center)
        margin: .5rem 0
      h3
        font: 1.25rem $cursive
        color: $color--dark-violet
        display: flex
        gap: .5rem
        align-items: center
        svg
          max-width: 1rem
    &:nth-child(3)
      @media #{$W575}
        +flex-parent(25%, row nowrap, flex-start, center)
    &:nth-child(4)
      @media #{$W575}
        +flex-parent(35%, row nowrap, center, center)
    &:nth-child(5)
      @media #{$W575}
        +flex-parent(25%, row nowrap, center, center)
    &:nth-child(6)
      @media #{$W575}
        +flex-parent(15%, row nowrap, flex-end, center)

.cart-content,
.cart-details
  .form-control
    width: 60%
    box-shadow: inset 0px 0px 30px 0px #dedede
    border: 0
    height: 2rem
    font-size: .8rem

.cart-summary
  width: 100%
  color: #511826
  border-top: 1px dashed #ccc
  border-bottom: 1px dashed #ccc
  h4
    font-size: 1rem

.cart-details
  color: #43373a
  h2
    font: 1.25rem $cursive
    color: #511826
  .form-control
    width: 90%
    @media #{$W768}
      width: 100%
  .form-group
    textarea
      height: 5rem
  .btn-primary
    width: 100%
    &.active,
    &.focus
      background: #974558
      color: #fff
    @media #{$w360}
      width: 100%!important
    &:hover
      background-color: #974558
      color: #fff
      cursor: pointer

.cart3
  @media #{$W768}
    +flex-parent(100%, row wrap, center, center)

.cart3-title
  +flex-parent(100%, row wrap, flex-start, center)
  color: #511826
  @media #{$W575}
    text-align: center
  h1
    font: 2.5rem $cursive
  @media #{$W768}
    [class*="col-"]:last-child
      display: none

.cart3-content
  font-size: 0.7rem
  color: #626262
  [class*="col-"]
    +flex-parent(100%, row wrap, flex-start, center)
    height: 5rem
    overflow: hidden
    @media #{$W575}
      height: auto
    .form-control
      display: inline-block
      width: 2rem
      height: 1.5rem
      border-radius: 0
    &:nth-child(1)
      +flex-parent(100%, column nowrap, center, flex-start)
      @media #{$W575}
        +flex-parent(100%, column nowrap, flex-start, center)
        margin: .5rem 0
      h3
        font: .9rem $sans-serif
        color: #974558
        font-style: italic
    &:nth-child(3)
      @media #{$W575}
        +flex-parent(25%, row nowrap, flex-start, center)
    &:nth-child(4)
      @media #{$W575}
        +flex-parent(35%, row nowrap, center, center)
    &:nth-child(5)
      @media #{$W575}
        +flex-parent(25%, row nowrap, center, center)
    &:nth-child(6)
      @media #{$W575}
        +flex-parent(15%, row nowrap, flex-end, center)

.cart3-content,
.cart3-details
  .form-control
    width: 60%
    box-shadow: inset 0px 0px 30px 0px #dedede
    border: 0
    height: 2rem
    font-size: .6rem

.cart3-summary
  width: 100%
  font-size: 0.7rem
  color: #511826
  border-top: 1px dashed #ccc
  border-bottom: 1px dashed #ccc
  h4
    font-size: 1rem

.cart3-details
  font-size: 0.7rem
  color: #43373a
  h2
    font: 1.25rem $cursive
    color: #511826
  .form-control
    width: 90%
    @media #{$W768}
      width: 100%
  .form-group
    textarea
      height: 5rem
  .btn-primary
    border-radius: 0
    border: 2px solid #974558
    color: #974558
    font-size: 0.7rem
    background: #fff
    width: 100%
    &:hover
      background-color: #974558
      color: #fff
      cursor: pointer
.invalid-feedback
  background-color: #F44336
  display: block
  padding: 0.125rem .5rem
  color: #fff
.order-time-notice
  color: #c12042
  font: 1.25rem $cursive
  background: #ffe9bd
  padding: 1rem
  border-radius: 0.5rem
.order-bh-notice
  width: 90%
  border-radius: 0.25rem
  border: 2px solid #984658
  color: #984658
  padding: 0.5rem 1rem
  .btn
    border-radius: 0.25rem
.order-no-time-notice
  width: 90%
  font-size: .65rem
  border-radius: 0.25rem
  border: 2px solid #fdbb64
  color: #fdbb64
  padding: 0.5rem 1rem
.shop-card
  min-height: 10.5rem
  margin-bottom: 1rem
  border-color: #e8d5d9
  .shop-title
    font: 1rem $cursive
    color: #511826
    margin-bottom: 0.333rem
  a
    color: #b97d2d

.delivery
  position: absolute
  width: 4px
  height: 4px
  // width: 15px
  // height: 15px
  border-radius: 150px
  bottom: 3px
  left: calc(50% - 2px)
  content: " "
  display: block
  background: #4caf50
.delivery.busy
  background: #f64747

// Cart details V2
.cart-details-v2
  .order-delivery
    @media #{$W575}
      order: 1
  .order-calendar
    @media #{$W575}
      order: 2
  .order-details
    @media #{$W575}
      order: 3
  .delivery-type,
  .payment-type,
  .delivery-options-box,
  .payment-options-box
    margin-bottom: 1.5rem
  .filial-button
    width: 90%
    padding: 0.75rem 2.5rem
    margin-bottom: 1rem
    p
      margin-bottom: 0
  .flatpickr-calendar
    box-shadow: none
    border: 0
    .flatpickr-day.selected
      background: #511826
      border-color: #511826
  .timeslots-wrapper
    padding: 0 0.125rem
    overflow-y: scroll
    height: 10.85rem
    .btn
      flex: unset !important
      width: 3rem !important
      &:first-child,
      &:last-child
        border-top-left-radius: 0 !important
        border-top-right-radius: 0 !important
        border-bottom-left-radius: 0 !important
        border-bottom-right-radius: 0 !important
  .btn-selectors-group
    padding: 0.25rem 0
    flex-wrap: wrap
    .btn
      width: fit-content
      font-family: $sans-serif
      // margin-bottom: -2px
      // margin-right: -2px
      font-size: .75rem
      flex: auto
      // padding: 0.425rem 0.425rem
      &:first-child
        border-top-left-radius: .5rem
        border-bottom-left-radius: .5rem
      &:last-child
        border-top-right-radius: .5rem
        border-bottom-right-radius: .5rem
      &.active
        z-index: 0
      &:first-child
        margin-left: -1px
  .order-bh-notice,
  .order-no-time-notice
    width: 100%
  textarea
    height: inherit !important
