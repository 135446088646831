@import '../assets/css/bootstrap.css';

@import '~flatpickr/dist/flatpickr.min.css';

@import '~flatpickr/dist/plugins/confirmDate/confirmDate.css';

@import '~glightbox/dist/css/glightbox.min.css';

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 Saurabh Sharma
 * Licensed under  ()
 */

/*
 *  Owl Carousel - Core
 */

@import '~cookieconsent/build/cookieconsent.min.css';

@import '~dropzone/dist/dropzone.css';

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */

.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("../../node_modules/owl.carousel2/dist/assets/owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/*! nouislider - 14.7.0 - 4/6/2021 */

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

.noUi-handle:after,
.noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
  cursor: not-allowed;
}

.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

.toast-title {
  font-weight: 700;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFF;
}

.toast-message a:hover {
  color: #CCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}

.toast-close-button:focus,
.toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
}

#toast-container > .toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
}

#toast-container > .toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
}

#toast-container > .toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
}

#toast-container.toast-bottom-center > div,
#toast-container.toast-top-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-bottom-full-width > div,
#toast-container.toast-top-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }

  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

/* Variables */

body {
  background-color: #FDF9FB;
  font-family: "Inter", sans-serif;
}

.wrapper {
  background-color: #fff;
}

.modal {
  overflow: auto !important;
  font-size: 0.75rem;
}

.modal div {
  font-size: 0.75rem;
}

.pagination {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .pagination {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .pagination div {
    margin-bottom: 0.5rem;
  }
}

.pagination ul {
  display: inline;
}

.fixed-icons-nav {
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  height: 5rem;
  weight: 5rem;
}

.fixed-icons-nav [class*=fixed-icons-nav-] {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0 0.5rem 0.5rem 0;
}

.fixed-icons-nav img {
  max-width: 1.5rem;
}

.section {
  padding: 3rem 3rem;
}

.section .section-title {
  font-size: 5rem;
  line-height: 75%;
  color: #462D2A;
  font-weight: 900;
  font-family: "Noto Serif Display", serif;
  margin-bottom: 0;
}

@media only screen and (max-width: 1440px) {
  .section .section-title {
    font-size: 3.75rem;
  }
}

@media only screen and (max-width: 1366px) {
  .section .section-title {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .section .section-title {
    font-size: 2.75rem;
  }
}

.section a {
  color: #CC4E06;
  font-family: "Noto Serif Display", serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section a svg {
  margin-left: 0;
  transition: all 0.25s ease-out;
}

.section a:hover svg {
  margin-left: 1rem;
}

.section-wide {
  padding: 6rem 12rem;
}

@media only screen and (max-width: 1366px) {
  .section-wide {
    padding: 6rem 6rem;
  }
}

@media only screen and (max-width: 575px) {
  .section-wide {
    padding: 3rem 2rem;
  }
}

.btn-primary {
  color: #462D2A;
  font-family: "Noto Serif Display", serif;
  background-color: transparent;
  border-color: #b0a3a1;
  border-width: 2px;
  font-size: 1.25rem;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #462D2A;
  color: #FFF !important;
  border-color: #462D2A;
}

.btn-primary svg {
  max-width: 1.5rem;
}

.btn-secondary {
  color: #551920;
  font-family: "Noto Serif Display", serif;
  background-color: transparent;
  border-color: #c3a6ad;
  border-width: 1px;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #551920;
  color: #FFF !important;
  border-color: #551920;
}

.btn-secondary svg {
  max-width: 1.5rem;
}

.btn-danger {
  color: #F63349;
  font-family: "Noto Serif Display", serif;
  background-color: transparent;
  border-color: #fa9ca7;
  border-width: 2px;
  font-size: 1.25rem;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-danger:hover {
  background-color: #F63349;
  color: #FFF !important;
  border-color: #F63349;
}

.btn-danger svg {
  max-width: 1.5rem;
}

#toast-container > div {
  border-radius: 1rem;
  box-shadow: none;
}

#toast-container > div:hover {
  box-shadow: none;
}

.toast-info {
  background-color: #0052c5;
}

.toast-error {
  background-color: #d30000;
}

.toast-success {
  background-color: #00ba19;
}

.toast-warning {
  background-color: #ff9500;
}

.modal-content {
  border-radius: 2rem !important;
}

.modal-content .modal-header {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.alert {
  border-radius: 1rem;
}

.badge {
  border-radius: 3rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.page-index .section-hero {
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  min-height: 50rem;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 1440px) {
  .page-index .section-hero {
    min-height: 42.5rem;
  }
}

@media only screen and (max-width: 1366px) {
  .page-index .section-hero {
    min-height: 35rem;
  }
}

.page-index .section-hero .hero-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.page-index .section-hero .hero-content-wrapper .hero-title {
  font-size: 8rem;
  line-height: 75%;
  color: #FFF;
  font-weight: 900;
  font-family: "Noto Serif Display", serif;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.1450980392);
  margin-bottom: 0;
}

@media only screen and (max-width: 1440px) {
  .page-index .section-hero .hero-content-wrapper .hero-title {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 1366px) {
  .page-index .section-hero .hero-content-wrapper .hero-title {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .page-index .section-hero .hero-content-wrapper .hero-title {
    font-size: 3.5rem;
  }
}

.page-index .section-hero .hero-content-wrapper .hero-cta-button {
  color: #FFF;
  font-family: "Noto Serif Display", serif;
  font-size: 2rem;
  text-decoration: none;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.3137254902);
}

.page-index .section-hero .hero-content-wrapper .hero-cta-button svg {
  margin-left: 0;
  transition: all 0.25s ease-out;
}

.page-index .section-hero .hero-content-wrapper .hero-cta-button:hover svg {
  margin-left: 1rem;
}

.page-index .section-intro .section-content-wrapper {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 2fr;
}

@media only screen and (max-width: 768px) {
  .page-index .section-intro .section-content-wrapper {
    grid-template-columns: 100%;
  }
}

.page-index .section-intro .section-content-wrapper .section-description {
  font-size: 2rem;
  margin-top: 5rem;
  color: #462D2A;
  font-family: "Noto Serif Display", serif;
}

@media only screen and (max-width: 1440px) {
  .page-index .section-intro .section-content-wrapper .section-description {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 1366px) {
  .page-index .section-intro .section-content-wrapper .section-description {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .page-index .section-intro .section-content-wrapper .section-description {
    font-size: 1.25rem;
  }
}

.page-index .section-categories {
  background-color: #FFF;
  margin: 4rem 6rem;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.062745098);
  border-radius: 3.5rem;
}

@media only screen and (max-width: 768px) {
  .page-index .section-categories {
    margin: 0rem 2rem;
  }
}

@media only screen and (max-width: 575px) {
  .page-index .section-categories {
    margin: 0rem 1rem;
  }
}

.page-index .section-categories .categories-content-wrapper {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 575px) {
  .page-index .section-categories .categories-content-wrapper {
    grid-template-columns: 100%;
  }
}

.page-index .section-categories .categories-content-wrapper .category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 0.5rem solid #FDF9FB;
}

@media only screen and (max-width: 768px) {
  .page-index .section-categories .categories-content-wrapper .category {
    border-right: 0;
  }
}

.page-index .section-categories .categories-content-wrapper .category:last-child {
  border-right: none;
}

.page-index .section-categories .categories-content-wrapper .category .category-image {
  width: 75%;
}

.page-index .section-categories .categories-content-wrapper .category .category-title {
  font-size: 1.75rem;
  text-align: center;
  color: #551920;
}

@media only screen and (max-width: 768px) {
  .page-index .section-categories .categories-content-wrapper .category .category-title {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 575px) {
  .page-index .section-categories .categories-content-wrapper .category .category-title {
    font-size: 1.0125rem;
  }
}

.page-index .section-favorite .section-content-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
}

.page-index .section-favorite .product-content-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 2rem;
}

@media only screen and (max-width: 768px) {
  .page-index .section-favorite .product-content-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.page-index .section-favorite .product-content-wrapper .col-12 article {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page-index .section-favorite .product-content-wrapper .col-12 article a {
  align-items: start;
  justify-content: center;
}

.page-index .section-favorite .product-content-wrapper .col-12 article .gallery-item {
  justify-content: center;
}

.page-index .section-discounts {
  background-color: #FFF;
  margin: 4rem 6rem;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.062745098);
  border-radius: 3.5rem;
}

@media only screen and (max-width: 575px) {
  .page-index .section-discounts {
    margin: 2rem 0rem;
    border-radius: 0;
  }
}

.page-index .section-discounts .section-content-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 20rem;
}

@media only screen and (max-width: 1366px) {
  .page-index .section-discounts .section-content-wrapper {
    grid-template-columns: 1fr;
    gap: 10rem;
  }
}

@media only screen and (max-width: 1024px) {
  .page-index .section-discounts .section-content-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    gap: 5rem;
  }
}

.page-index .section-discounts .section-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.page-index .section-discounts .section-text-wrapper .section-title {
  color: #551920;
}

.page-index .section-discounts .section-text-wrapper .section-description {
  color: #686868;
  font-size: 1.25rem;
}

.page-index .section-discounts .product-content-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.page-index .section-discounts .product-content-wrapper .col-12 article {
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1440px) {
  .page-index .section-discounts .product-content-wrapper .col-12 article {
    width: 20rem;
  }
}

@media only screen and (max-width: 1366px) {
  .page-index .section-discounts .product-content-wrapper .col-12 article {
    width: 15rem;
  }
}

@media only screen and (max-width: 1024px) {
  .page-index .section-discounts .product-content-wrapper .col-12 article {
    width: 100%;
  }
}

.page-index .section-discounts .product-content-wrapper .col-12 article a {
  align-items: start;
  justify-content: center;
}

.page-index .section-discounts .product-content-wrapper .col-12 article .gallery-item {
  justify-content: center;
}

.page-index .section-presentation .section-image-wrapper {
  display: flex;
  justify-content: end;
}

@media only screen and (max-width: 768px) {
  .page-index .section-presentation .section-image-wrapper {
    justify-content: center;
  }
}

.page-index .section-presentation .section-image-wrapper img {
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.0901960784);
  border-radius: 3rem;
}

.page-index .section-presentation .section-content-wrapper {
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 768px) {
  .page-index .section-presentation .section-content-wrapper {
    grid-template-columns: 100%;
  }
}

.page-index .section-presentation .section-content-wrapper .section-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.page-index .section-presentation .section-content-wrapper .section-description {
  margin-left: 6rem;
  color: #686868;
  font-size: 1.25rem;
}

@media only screen and (max-width: 1024px) {
  .page-index .section-presentation .section-content-wrapper .section-description {
    margin-left: 2rem;
  }
}

.page-index .section-presentation .section-content-wrapper .section-cta-button {
  font-size: 1.5rem;
}

.page-index .trustpilot {
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

.page-index .trustpilot svg,
.page-index .trustpilot img {
  width: 10rem;
}

.page-index .section-branches .branch-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 768px) {
  .page-index .section-branches .branch-content-wrapper {
    grid-template-columns: 100%;
    text-align: center;
  }
}

.page-index .section-branches .branch-content-wrapper .branch {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-right: 1px solid #E8E7EB;
  padding: 2rem 5rem;
}

@media only screen and (max-width: 1024px) {
  .page-index .section-branches .branch-content-wrapper .branch {
    padding: 2rem 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .page-index .section-branches .branch-content-wrapper .branch {
    border-right: none;
  }
}

.page-index .section-branches .branch-content-wrapper .branch:first-child {
  padding-left: 0rem;
}

@media only screen and (max-width: 768px) {
  .page-index .section-branches .branch-content-wrapper .branch:first-child {
    padding-left: 2rem;
  }
}

.page-index .section-branches .branch-content-wrapper .branch:last-child {
  border-right: none;
  padding-right: 0rem;
}

@media only screen and (max-width: 768px) {
  .page-index .section-branches .branch-content-wrapper .branch:last-child {
    padding-right: 2rem;
  }
}

.page-index .section-branches .branch-content-wrapper .branch .title {
  font-size: 0.85rem;
  color: #686868;
}

.page-index .section-branches .branch-content-wrapper .branch .addess {
  font-size: 1.25rem;
  color: #CC4E06;
  font-family: "Noto Serif Display", serif;
}

.page-index .section-branches .branch-content-wrapper .branch .hours {
  font-size: 0.85rem;
  color: #462D2A;
}

.navbar {
  font: 1.175rem "Noto Serif Display", serif;
  padding: 1rem 3rem;
  justify-content: space-between;
  background-color: #FDF9FB;
  gap: 1rem;
  transition: all 0.25s ease-out;
}

@media only screen and (max-width: 575px) {
  .navbar {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem;
  }
}

.navbar .navbar-toggler-right {
  position: relative;
}

@media only screen and (max-width: 575px) {
  .navbar .navbar-toggler-right {
    position: absolute;
  }
}

.navbar .navbar-wrapper {
  overflow-x: hidden;
}

@media only screen and (max-width: 1024px) {
  .navbar .navbar-nav {
    padding: 0 1rem;
    display: grid !important;
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: 768px) {
  .navbar .navbar-nav {
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 0 1rem;
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

.navbar .nav-item .nav-link {
  color: #462D2A;
  transition: all 0.25s ease-out;
  padding: 0.5rem 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 35rem;
  font-weight: 500;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .navbar .nav-item .nav-link {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1366px) {
  .navbar .nav-item .nav-link {
    padding: 0.5rem 0.85rem;
    font-size: 0.9rem;
  }
}

.navbar .nav-item .nav-link:hover {
  background-color: #FFF;
  color: #551920;
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1440px) {
  .navbar .nav-item .nav-link:hover {
    padding: 0.5rem 1rem;
  }
}

@media only screen and (max-width: 1366px) {
  .navbar .nav-item .nav-link:hover {
    padding: 0.5rem 0.85rem;
  }
}

.navbar .nav-item .nav-link:hover:after {
  content: "";
  border-width: 10px 10px 0 10px;
  border-color: #FFF transparent transparent transparent;
  border-style: solid;
  position: absolute;
  bottom: -9px;
}

.navbar .nav-item .active {
  background-color: #FFF;
  color: #551920;
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1440px) {
  .navbar .nav-item .active {
    padding: 0.5rem 1rem;
  }
}

@media only screen and (max-width: 1366px) {
  .navbar .nav-item .active {
    padding: 0.5rem 0.85rem;
  }
}

.navbar .nav-item .nav-offcanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .nav-item .nav-offcanvas:hover:after {
  content: none;
}

.navbar .navbar-brand {
  margin-right: 0;
}

.navbar .navbar-brand img {
  width: 15rem;
}

@media only screen and (max-width: 1440px) {
  .navbar .navbar-brand img {
    width: 12rem;
  }
}

@media only screen and (max-width: 1366px) {
  .navbar .navbar-brand img {
    width: 10rem;
  }
}

.navbar-toolbar {
  border-left: 0.125rem solid #E8E7EB;
  padding-left: 1rem;
  margin-left: -1rem;
}

@media only screen and (max-width: 768px) {
  .navbar-toolbar {
    border-left: 0;
  }
}

.navbar-toolbar .navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .navbar-toolbar .navbar-nav {
    grid-template-columns: auto auto auto;
  }
}

.navbar-toolbar .navbar-nav .nav-item .nav-link {
  display: flex;
  padding: 0;
  align-items: center;
  height: 2.5rem;
}

.navbar-toolbar .navbar-nav .nav-item .nav-link:hover {
  background: none;
  padding: inherit;
}

.navbar-toolbar .navbar-nav .nav-item .nav-link:hover:after {
  content: none;
}

.navbar-toolbar .navbar-nav .nav-item-profile .nav-link,
.navbar-toolbar .navbar-nav .nav-item-admin .nav-link {
  margin-bottom: 0.5rem;
}

.sticky-top {
  z-index: 10;
}

.form-control-search {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 35rem;
  color: #FFF;
  width: 2rem;
  padding: 1rem;
  color: #732839;
  position: relative;
  right: -2rem;
  text-indent: -10000px;
  transition: all 0.25s ease-out;
}

.form-control-search::placeholder {
  color: #462D2A;
  opacity: 1;
  font-style: italic;
}

.form-control-search:-ms-input-placeholder {
  font-style: italic;
  color: #462D2A;
}

.form-control-search::-ms-input-placeholder {
  font-style: italic;
  color: #462D2A;
}

.form-control-search:hover,
.form-control-search:focus,
.form-control-search.focus,
.form-control-search.active,
.form-control-search:active {
  width: 10rem;
  text-indent: 0;
  background-color: transparent;
  color: #551920;
  background-color: #FFF;
}

.btn-search {
  color: #462D2A;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.25s ease-out;
}

#header_placeholder {
  height: 85px;
  width: 100%;
  display: none;
}

.navbar-toggler {
  top: 0.95rem;
  right: 1rem;
  width: 1.5rem;
  height: 1rem;
  border: 0;
  cursor: pointer;
}

@media only screen and (max-width: 823px) {
  .navbar-toggler {
    top: 2.45rem;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-toggler {
    top: 0.95rem;
  }
}

.navbar-toggler span {
  display: block;
  position: absolute;
  height: 2px;
  width: 30px;
  background: #551920;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: all 0.25s ease-out;
}

.navbar-toggler span:nth-child(1) {
  top: 0px;
}

.navbar-toggler span:nth-child(2) {
  top: 9px;
}

.navbar-toggler span:nth-child(3) {
  top: 18px;
}

.navbar-toggler.open span:nth-child(1) {
  top: 9px;
  transform: rotate(135deg);
}

.navbar-toggler.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navbar-toggler.open span:nth-child(3) {
  top: 9px;
  transform: rotate(-135deg);
}

.nav-small {
  font-size: 1.125rem;
  padding: 0.3rem 2rem 0.3rem 2rem;
  background-color: #FFF;
}

.nav-small img {
  width: 10rem;
  transition: all 0.25s ease-out;
}

.nav-small .form-control-search:hover,
.nav-small .form-control-search:focus,
.nav-small .form-control-search.focus,
.nav-small .form-control-search.active,
.nav-small .form-control-search:active {
  color: #551920;
  background-color: #FDF9FB;
}

@media only screen and (max-width: 768px) {
  .nav-small img {
    width: 7rem;
  }
}

.cart-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.33rem;
  font-family: "Inter", sans-serif;
  transition: all 0.25s ease-out;
}

.cart-icon .badge {
  background-color: #F63349;
  color: #FFF;
  border-radius: 5rem;
  padding: 0.25rem 0.75rem;
  font-weight: 800;
  line-height: initial;
  transition: all 0.25s ease-out;
}

.cart-icon:hover .badge {
  background-color: #551920;
  color: #FFF;
}

.cart-icon:hover .badge:after {
  content: none;
}

@media only screen and (max-width: 823px) {
  .cart-icon {
    top: 3rem;
    right: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .cart-icon {
    top: 1.5rem;
  }
}

.offcanvas-modal.left .modal-dialog,
.offcanvas-modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  top: 0;
  -webkit-transform: translate3d(0%, 0, 0);
  -webkit-transform--ms-transform: translate3d(0%, 0, 0);
  -webkit-transform--ms-transform--o-transform: translate3d(0%, 0, 0);
  -webkit-transform--ms-transform-transform: translate3d(0%, 0, 0);
}

@media only screen and (max-width: 575px) {
  .offcanvas-modal.left .modal-dialog,
  .offcanvas-modal.right .modal-dialog {
    width: 100%;
  }
}

.offcanvas-modal.left .modal-content,
.offcanvas-modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

/*Left*/

.offcanvas-modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -webkit-transition--moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -webkit-transition--moz-transition--o-transition: opacity 0.3s linear, left 0.3s ease-out;
  -webkit-transition--moz-transition-transition: opacity 0.3s linear, left 0.3s ease-out;
}

.offcanvas-modal.left.fade.show .modal-dialog {
  left: 0;
}

/*Right*/

.offcanvas-modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -webkit-transition--moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -webkit-transition--moz-transition--o-transition: opacity 0.3s linear, right 0.3s ease-out;
  -webkit-transition--moz-transition-transition: opacity 0.3s linear, right 0.3s ease-out;
}

.offcanvas-modal.right.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */

.offcanvas-modal {
  border-radius: 0;
  border: none;
}

.offcanvas-modal .close svg {
  max-width: 2rem;
  max-height: 2rem;
}

.offcanvas-modal .modal-header,
.offcanvas-modal .modal-body {
  padding: 0;
}

.modal-dialog-slideout .modal-content {
  border: 0;
  padding: 2.5rem 2.5rem;
  gap: 2rem;
  border-radius: 0 !important;
}

.offcanvas-modal .modal-content .modal-header {
  border-bottom-color: #EEEEEE;
  justify-content: space-between;
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 auto 0 0;
}

@media only screen and (max-width: 575px) {
  .modal-dialog-slideout {
    width: 80%;
  }
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  flex-flow: column;
}

.modal-dialog-slideout .modal-content {
  border: 0;
}

.navbar-offcanvas-pages {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.navbar-offcanvas-pages .offcanvas-socials {
  display: flex;
  gap: 1rem;
}

.navbar-offcanvas-pages .offcanvas-socials a {
  font-size: 2rem;
  color: #551920;
  transition: all 0.25s ease-out;
}

.navbar-offcanvas-pages .offcanvas-socials a:hover {
  color: #CC4E06;
}

.navbar-offcanvas-pages .navbar-nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.navbar-offcanvas-pages .navbar-nav .nav-item .nav-link {
  font-family: "Noto Serif Display", serif;
  font-size: 1.25rem;
  border-bottom: 1px solid #E8E7EB;
  color: #462D2A;
  transition: all 0.25s ease-out;
}

.navbar-offcanvas-pages .navbar-nav .nav-item .nav-link:hover {
  color: #CC4E06;
  padding-left: 1rem;
}

@media only screen and (max-width: 768px) {
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    position: absolute;
    overflow-y: scroll;
    max-height: 30rem;
  }
}

.menu-large {
  position: static !important;
}

.megamenu {
  padding: 1rem;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .megamenu .dropdown-header {
    padding: 3px 15px !important;
  }
}

@media only screen and (max-width: 768px) {
  .megamenu {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .megamenu li {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .megamenu li:last-child {
    margin-bottom: 0;
  }
}

.megamenu > div > li > ul {
  padding: 0;
  margin: 0;
}

.megamenu > div > li > ul > li {
  list-style: none;
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 5px 10px;
  clear: both;
  font-weight: normal;
  color: #732839;
  white-space: normal;
  font-family: "Inter", sans-serif;
}

.megamenu > div > li > ul > li > a:focus,
.megamenu > div > li > ul > li > a:hover {
  text-decoration: none;
}

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: #999999;
}

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.megamenu .dropdown-header {
  padding: 1rem 0;
}

@media only screen and (max-width: 575px) {
  .megamenu .dropdown-header {
    padding: 0 !important;
    margin: 0;
  }
}

.megamenu .dropdown-header a {
  font-size: 1.33rem;
  color: #732839;
  font-family: "Noto Serif Display", serif;
}

.megamenu .dropdown-menu {
  margin-top: -2px;
  border: 0;
  border-radius: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: -1.5rem;
  border: 0;
  box-shadow: 0px 0px 30px 0px #dedede;
}

@media only screen and (max-width: 768px) {
  .navbar-nav .open .dropdown-menu .dropdown-header {
    color: #fff;
  }
}

.megamenu .dropdown-item {
  border-right: 1px solid #E8E7EB;
  margin: 0.5rem 0rem;
}

.megamenu .dropdown-item:last-child {
  border-right: none;
}

.megamenu .dropdown-item:focus,
.megamenu .dropdown-item:hover {
  background-color: transparent;
  color: inherit;
}

.about-content {
  font-size: 0.7rem;
  color: #43373a;
}

.about-content aside p:nth-child(even) {
  color: #974558;
}

@media only screen and (max-width: 768px) {
  .about-content {
    text-align: center;
  }
}

.about-content a {
  color: #9f6b00;
}

.about-content a:hover {
  color: #ffbe5e;
  text-decoration: none;
}

.about-content h1,
.about-content h2,
.about-content h3 {
  font: 2.5rem "Noto Serif Display", serif;
  color: #511826;
}

.about-content h2 {
  font-size: 1.25rem;
}

.about-content h3 {
  font-size: 1rem;
  color: #974558;
}

@media only screen and (max-width: 768px) {
  .about-content article {
    padding: 0 !important;
    border: 0;
  }
}

.about-gallery .owl-item {
  height: 25rem;
}

.about-gallery .owl-item figure:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(58, 26, 20, 0.6);
  transition: all 0.5s ease;
}

.about-gallery .owl-item figure:hover:before {
  background-color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  z-index: -1;
}

.about-gallery .owl-item figure:hover:before a {
  z-index: 100000;
}

.about-gallery .owl-item figure a {
  z-index: 100000;
}

.about-gallery .owl-item figure a img {
  display: block;
  width: auto;
  height: 100%;
}

.about-gallery .owl-nav {
  font: 1.5rem;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  opacity: 0.5;
}

.about-gallery .owl-nav:hover {
  opacity: 1;
}

.about-gallery .owl-nav .owl-prev {
  position: absolute;
  left: 12.25rem;
}

.about-gallery .owl-nav .owl-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 3rem;
}

.about-gallery .owl-nav .owl-next {
  position: absolute;
  right: 12.25rem;
}

.about-gallery .owl-nav .owl-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 3rem;
}

.about-reviews {
  font-size: 0.7rem;
}

@media only screen and (max-width: 768px) {
  .about-reviews {
    text-align: center;
  }
}

.about-reviews .owl-nav {
  font: 1.5rem FontAwesome;
  color: #000;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20%;
  opacity: 0.5;
}

.about-reviews .owl-nav:hover {
  opacity: 1;
}

.about-reviews .owl-nav .owl-prev {
  position: absolute;
  left: 0;
}

.about-reviews .owl-nav .owl-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
}

.about-reviews .owl-nav .owl-next {
  position: absolute;
  right: 0;
}

.about-reviews .owl-nav .owl-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
}

.reviews {
  margin-bottom: 5rem;
}

.reviews h2 {
  font: 2.5rem "Noto Serif Display", serif;
  color: #511826;
  font-size: 1.25rem;
}

@media only screen and (max-width: 768px) {
  .reviews h2 {
    text-align: center;
  }
}

.modal-open {
  padding-right: 0 !important;
  overflow-Y: scroll;
}

.wrapper {
  background-color: #fff;
}

.contacts-content {
  font-size: 0.7rem;
  color: #43373a;
}

@media only screen and (max-width: 768px) {
  .contacts-content {
    text-align: center;
  }
}

.contacts-content a {
  color: #9f6b00;
}

.contacts-content a:hover {
  color: #ffbe5e;
  text-decoration: none;
}

.contacts-content h1,
.contacts-content h2,
.contacts-content h3 {
  font: 2.5rem "Noto Serif Display", serif;
  color: #511826;
}

.contacts-content h2 {
  font-size: 1.25rem;
}

.contacts-content h3 {
  font-size: 1rem;
  color: #974558;
}

.contacts-content article {
  min-height: 14rem;
}

.contacts-content article [class*=col-] {
  background: #f7f7f7;
}

@media only screen and (max-width: 768px) {
  .contacts-content article {
    padding: 0 !important;
    border: 0;
  }
}

.contacts-content aside p:nth-child(even) {
  color: #974558;
}

.contacts-content aside p:last-of-type {
  border: 0;
}

.contacts-content aside .btn-lg {
  border-radius: 0;
  border: 2px solid #974558;
  color: #974558;
  font-size: 0.7rem;
  background: #fff;
  width: 100%;
}

.contacts-content aside .btn-lg:hover {
  background-color: #974558;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .contacts-content .col-md-4.col-sm-12 {
    margin-top: 1rem;
  }
}

#map {
  widht: 100%;
  height: 25rem;
}

.modal-content {
  padding: 1rem 0;
  border: 0;
  border-radius: 0;
  box-shadow: 0px 0px 30px 0px #dedede;
}

.modal-content .modal-header {
  justify-content: center;
  border: 0;
}

.modal-content .modal-header h5.modal-title {
  color: #511826;
  font: 1.8rem "Noto Serif Display", serif;
}

.modal-content .modal-body form {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.modal-content .modal-body form .form-group {
  width: 80%;
}

.modal-content .modal-body form .form-group input,
.modal-content .modal-body form .form-group textarea {
  border: 0;
  border-radius: 0;
  text-align: center;
  height: 2.5rem;
  font-size: 0.7rem;
  box-shadow: inset 0px 0px 30px 0px #dedede;
}

.modal-content .modal-body form .form-group textarea {
  height: 7rem;
}

.modal-content .modal-body form .btn-primary {
  width: 80%;
  background-color: #fff;
  color: #974558;
  font-size: 0.7rem;
  height: 2.25rem;
  border: 2px solid #974558;
  border-radius: 0;
  text-transform: uppercase;
}

.modal-content .modal-body form .btn-primary:hover {
  cursor: pointer;
  background-color: #974558;
  color: #fff;
}

.catalog-title .show {
  display: contents;
}

.catalog-title .show .border {
  padding-right: 0.5rem;
  border-right: 1px solid #974558;
}

.catalog-title .show .list-inline-item a {
  color: #974558;
}

.catalog-title h1 {
  font: 2.5rem "Noto Serif Display", serif;
  color: #511826;
}

@media only screen and (max-width: 700px) {
  .catalog-title {
    text-align: center;
  }
}

.catalog-content {
  font-size: 0.9rem;
  color: #43373a;
}

.catalog-content li {
  border-bottom: 1px solid #f2f2f2;
}

.catalog-content a[aria-expanded=true] .fa-chevron-right {
  display: none;
}

.catalog-content a[aria-expanded=false] .fa-chevron-down {
  display: none;
}

@media only screen and (max-width: 700px) {
  .catalog-content {
    text-align: center;
  }
}

.catalog-content li {
  list-style-type: none;
}

.catalog-content a {
  color: #974558;
  text-decoration: none;
  transition: color 0.25s;
}

.catalog-content a:hover {
  color: #9f6b00;
}

.catalog-content .btn-secondary:hover {
  border-color: #551920;
}

.catalog-content h1,
.catalog-content h2,
.catalog-content h3 {
  font: 2rem "Noto Serif Display", serif;
  color: #462D2A;
}

.catalog-content h2 {
  font: 1.5rem "Noto Serif Display", serif;
  color: #551920;
}

.catalog-content h3 {
  font: 1.33rem "Noto Serif Display", serif;
  color: #551920;
}

.catalog-content .catalog-content-description {
  min-height: 4rem;
}

.catalog-content .catalog-content-description .catalog-description-fixed-content-height {
  display: block;
}

.catalog-content .catalog-content-allergens {
  min-height: 2rem;
}

.catalog-content .form-control,
.catalog-content #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .catalog-content .form-control-file {
  box-shadow: inset 0px 0px 30px 0px #dedede;
  border: 0;
  height: 2rem;
  border-radius: 0;
  font-size: 0.6rem;
}

.catalog-content .catalog-filter {
  margin-top: 2rem;
}

.catalog-content .catalog-filter .form-group {
  margin-bottom: 2rem;
}

.catalog-content .catalog-filter .title {
  font: 1.75rem "Noto Serif Display", serif;
  display: block;
  margin-bottom: 1rem;
  color: #511826;
}

.catalog-content .catalog-filter label {
  color: #000000;
  display: block;
}

.catalog-content .catalog-filter .form-control,
.catalog-content .catalog-filter #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .catalog-content .catalog-filter .form-control-file {
  width: 100%;
  max-width: 100% !important;
}

.catalog-content .catalog-filter .filter-floors-count .floors-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.catalog-content .catalog-filter .filter-floors-count .form-check {
  display: inline-flex;
  align-items: center;
}

.catalog-content .catalog-filter .filter-floors-count .form-check label {
  font-size: 0.675rem;
  color: #333333;
}

.catalog-content .catalog-filter .filter-floors-count input[type=radio] {
  appearance: none;
  background-color: #F0F0F0;
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalog-content .catalog-filter .filter-floors-count input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: #8D4A58;
  display: block;
}

.catalog-content .catalog-filter .filter-floors-count input[type=radio]:checked::before {
  transform: scale(1);
}

.catalog-content .catalog-filter .filter-floors-count input[type=radio] + .form-control,
.catalog-content .catalog-filter .filter-floors-count #makeAcakeStep1 input[type=radio] + .form-control-file,
#makeAcakeStep1 .catalog-content .catalog-filter .filter-floors-count input[type=radio] + .form-control-file {
  margin-top: 1em;
}

.catalog-content .catalog-filter .filter-price #priceSlider {
  margin-top: 2.5rem;
  border-radius: 5rem;
  background-color: #D9D9D9;
  height: 0.25rem;
  border: 0;
  box-shadow: none;
}

.catalog-content .catalog-filter .filter-price #priceSlider .noUi-connects {
  height: 0.25rem;
}

.catalog-content .catalog-filter .filter-price #priceSlider .noUi-connect {
  background-color: #8D4A58;
}

.catalog-content .catalog-filter .filter-price #priceSlider .noUi-handle {
  width: 1rem;
  height: 1rem;
  top: -8px;
  border: 0;
  box-shadow: none;
  border-radius: 35rem;
  background-color: #8D4A58;
}

.catalog-content .catalog-filter .filter-price #priceSlider .noUi-handle::before,
.catalog-content .catalog-filter .filter-price #priceSlider .noUi-handle::after {
  content: none;
}

.catalog-content .catalog-filter .filter-price #priceSlider .noUi-tooltip {
  background-color: #BD8691;
  color: #ffffff;
  font-weight: bold;
  padding: 1px 5px;
  border: 0;
}

.catalog-content .catalog-filter .filter-buttons {
  display: grid;
  grid-template-columns: 1fr auto;
}

.catalog-content aside article:first-child ul {
  padding-left: 1.5rem;
}

@media only screen and (max-width: 700px) {
  .catalog-content aside article:first-child ul {
    padding-left: 0;
  }
}

.catalog-content aside article:first-child li {
  list-style-image: url(/../assets/img/list-style-image.png);
}

.catalog-content aside article:first-child li:hover {
  list-style-image: url(/../assets/img/list-style-image-hover.png);
}

@media only screen and (max-width: 700px) {
  .catalog-content aside article:first-child li {
    list-style-image: none;
  }

  .catalog-content aside article:first-child li:hover {
    list-style-image: none;
  }
}

.catalog-content aside article:first-child .depth:last-child {
  border: 0;
}

.catalog-content aside article:first-child .depth-1 {
  font-size: 1.25rem;
  font-family: "Noto Serif Display", serif;
  font-style: normal;
}

.catalog-content aside article:first-child .depth-1 a {
  color: #462D2A;
}

.catalog-content aside article:first-child .depth-2 {
  font-size: 1rem;
  font-style: normal;
}

.catalog-content aside article:first-child .depth-2 a {
  color: #551920;
}

.catalog-content aside article:first-child .depth-3 {
  font-size: 0.95rem;
  font-style: normal;
}

.catalog-content aside article:first-child .depth-3 a {
  color: #732839;
}

@media only screen and (max-width: 700px) {
  .catalog-content aside article:last-child {
    margin-bottom: 1rem;
  }
}

.catalog-content aside article:last-child .form-control,
.catalog-content aside article:last-child #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .catalog-content aside article:last-child .form-control-file {
  max-width: 90%;
}

@media only screen and (max-width: 700px) {
  .catalog-content aside article:last-child .form-control,
  .catalog-content aside article:last-child #makeAcakeStep1 .form-control-file,
  #makeAcakeStep1 .catalog-content aside article:last-child .form-control-file {
    margin: 0 auto;
    text-align: center;
  }
}

.catalog-content .conditery,
.catalog-content .services {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.catalog-content .conditery i,
.catalog-content .services i {
  font-size: 1rem;
}

.catalog-content .conditery span,
.catalog-content .services span {
  width: 100%;
}

.catalog-content .conditery article,
.catalog-content .services article {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 2rem;
  transition: all 0.25s ease-out;
  text-align: center;
}

.catalog-content .conditery article:hover,
.catalog-content .services article:hover {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 768px) {
  .catalog-content .conditery article,
  .catalog-content .services article {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

@media only screen and (max-width: 700px) {
  .catalog-content .conditery article,
  .catalog-content .services article {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0 !important;
  }
}

.catalog-content .conditery article img,
.catalog-content .services article img {
  height: 15rem;
  width: 100%;
  object-fit: cover;
  border-radius: 1.25rem;
}

.catalog-content .conditery article .product-title a,
.catalog-content .services article .product-title a {
  font-family: "Noto Serif Display", serif;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 0.25rem;
}

.catalog-content .conditery article .product-title svg,
.catalog-content .services article .product-title svg {
  max-width: 1.5rem;
}

.catalog-content .culinary {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.6rem;
}

.catalog-content .culinary article {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 2rem;
  transition: all 0.25s ease-out;
  text-align: center;
}

.catalog-content .culinary article:hover {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 768px) {
  .catalog-content .culinary article {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.catalog-content .culinary article .product-title {
  font-family: "Noto Serif Display", serif;
  justify-content: center;
  text-align: center;
}

.catalog-content .culinary .thumb,
.catalog-content .culinary .details,
.catalog-content .culinary .price,
.catalog-content .culinary .quantity {
  display: flex;
  width: auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.catalog-content .culinary .thumb {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .catalog-content .culinary .thumb {
    margin-right: 0 !important;
  }
}

.catalog-content .culinary .thumb img {
  max-width: 100%;
  border-radius: 0.25rem;
}

.catalog-content .culinary .details {
  width: 40%;
}

@media only screen and (max-width: 768px) {
  .catalog-content .culinary .details {
    text-align: center;
  }

  .catalog-content .culinary .details h3 {
    margin-top: 0.5rem !important;
  }
}

.catalog-content .culinary .price {
  width: 20%;
}

@media only screen and (max-width: 768px) {
  .catalog-content .culinary .price {
    margin: 0.5rem 0;
  }
}

.catalog-content .culinary .quantity {
  display: flex;
  width: 20%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.catalog-content .culinary .quantity .form-control,
.catalog-content .culinary .quantity #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .catalog-content .culinary .quantity .form-control-file {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .catalog-content .culinary .quantity {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .catalog-content .culinary .thumb,
  .catalog-content .culinary .details,
  .catalog-content .culinary .price {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.catalog-content .services img {
  height: 30%;
}

.pagination {
  display: flex;
  justify-content: start !important;
  text-align: center;
  gap: 1rem;
}

.pagination li {
  border-bottom: none;
}

.pagination .page-item .page-link {
  padding: inherit;
  font-size: 0.85rem;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 1rem;
  transition: all 0.25s ease-out;
  border: none;
}

.pagination .page-item:hover .page-link {
  background-color: #974558;
  color: #fff;
}

.pagination .page-item.active .page-link {
  background-color: #974558;
  color: #fff;
}

[id*=_wrapper] {
  background-color: #fff;
  max-height: 100%;
  height: auto !important;
}

#standalone {
  transform: scale(0.8);
}

.popup_visible #standalone {
  transform: scale(1);
}

[id*=makeAcake] .btn-secondary {
  border-radius: 0.25rem;
  border: 1px solid #974558;
  color: #974558;
  font-size: 0.6rem;
  background: #fff;
}

[id*=makeAcake] .btn-secondary:hover {
  background-color: #974558;
  color: #fff;
  cursor: pointer;
}

.cakesWrapper {
  width: 100%;
  margin: 3rem 0;
  text-align: center;
}

#cakeWeight1,
#cakeWeight2,
#cakeWeight3,
#cakeWeight4,
#cakeWeight5 {
  width: 50%;
  margin: 0 auto;
}

#makeAcakeStep1 {
  display: flex !important;
  flex-flow: row wrap !important;
  justify-content: center !important;
  align-items: stretch !important;
  font-size: 0.7rem;
  font-style: italic;
}

@media only screen and (max-width: 768px) {
  #makeAcakeStep1 img {
    max-width: 50% !important;
  }
}

#makeAcakeStep1 h3,
#makeAcakeStep1 h4 {
  font: 2rem "Noto Serif Display", serif;
  color: #974558;
}

#makeAcakeStep1 h4 {
  font-size: 0.9rem;
}

#makeAcakeStep1 span {
  width: 100%;
}

#makeAcakeStep1 [class*=cakeType-] {
  display: flex;
  width: 33.3%;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  transition: 0.125s;
}

@media only screen and (max-width: 768px) {
  #makeAcakeStep1 [class*=cakeType-] {
    display: flex;
    width: 50%;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}

@media only screen and (max-width: 575px) {
  #makeAcakeStep1 [class*=cakeType-] {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

#makeAcakeStep1 [class*=cakeType-]:hover {
  background: #f7f7f7;
}

#makeAcakeStep1 .form-control,
#makeAcakeStep1 .form-control-file {
  margin: 0 auto;
  text-align: center;
  width: auto;
  box-shadow: inset 0px 0px 30px 0px #dedede;
  border: 0;
  height: 2rem;
  font-size: 0.6rem;
}

#makeAcakeStep1 .form-control-file {
  box-shadow: none;
}

#makeAcakeStep2_cakeType-1,
#makeAcakeStep2_cakeType-5,
#makeAcakeStep2_cakeType-4,
#makeAcakeStep2_cakeType-3,
#makeAcakeStep2_cakeType-2 {
  display: flex !important;
  flex-flow: column nowrap !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 0.7rem;
  font-style: italic;
  background-color: #fff;
  width: 100%;
}

#makeAcakeStep2_cakeType-1 span,
#makeAcakeStep2_cakeType-5 span,
#makeAcakeStep2_cakeType-4 span,
#makeAcakeStep2_cakeType-3 span,
#makeAcakeStep2_cakeType-2 span,
#makeAcakeStep2_cakeType-1 .details,
#makeAcakeStep2_cakeType-5 .details,
#makeAcakeStep2_cakeType-4 .details,
#makeAcakeStep2_cakeType-3 .details,
#makeAcakeStep2_cakeType-2 .details {
  width: 100%;
}

#makeAcakeStep2_cakeType-1 .details,
#makeAcakeStep2_cakeType-5 .details,
#makeAcakeStep2_cakeType-4 .details,
#makeAcakeStep2_cakeType-3 .details,
#makeAcakeStep2_cakeType-2 .details {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-1 .details,
  #makeAcakeStep2_cakeType-5 .details,
  #makeAcakeStep2_cakeType-4 .details,
  #makeAcakeStep2_cakeType-3 .details,
  #makeAcakeStep2_cakeType-2 .details {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

#makeAcakeStep2_cakeType-1 .tastes,
#makeAcakeStep2_cakeType-5 .tastes,
#makeAcakeStep2_cakeType-4 .tastes,
#makeAcakeStep2_cakeType-3 .tastes,
#makeAcakeStep2_cakeType-2 .tastes {
  display: flex;
  width: 50%;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 80vh;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-1 .tastes,
  #makeAcakeStep2_cakeType-5 .tastes,
  #makeAcakeStep2_cakeType-4 .tastes,
  #makeAcakeStep2_cakeType-3 .tastes,
  #makeAcakeStep2_cakeType-2 .tastes {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    max-height: auto;
  }
}

#makeAcakeStep2_cakeType-1 .floors,
#makeAcakeStep2_cakeType-5 .floors,
#makeAcakeStep2_cakeType-4 .floors,
#makeAcakeStep2_cakeType-3 .floors,
#makeAcakeStep2_cakeType-2 .floors {
  display: flex;
  width: 50%;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-1 .floors,
  #makeAcakeStep2_cakeType-5 .floors,
  #makeAcakeStep2_cakeType-4 .floors,
  #makeAcakeStep2_cakeType-3 .floors,
  #makeAcakeStep2_cakeType-2 .floors {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

#makeAcakeStep2_cakeType-1 .floors div,
#makeAcakeStep2_cakeType-5 .floors div,
#makeAcakeStep2_cakeType-4 .floors div,
#makeAcakeStep2_cakeType-3 .floors div,
#makeAcakeStep2_cakeType-2 .floors div {
  display: flex;
  width: 50%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0.25rem;
  color: #fff;
  height: 5rem;
  background-color: #dedede;
  background-image: url("../assets/img/cake-deco.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 1rem 1rem 0 0;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-1 .floors div,
  #makeAcakeStep2_cakeType-5 .floors div,
  #makeAcakeStep2_cakeType-4 .floors div,
  #makeAcakeStep2_cakeType-3 .floors div,
  #makeAcakeStep2_cakeType-2 .floors div {
    width: 100% !important;
    flex-flow: column nowrap !important;
    align-items: center !important;
  }
}

#makeAcakeStep2_cakeType-1 .additional-tastes,
#makeAcakeStep2_cakeType-5 .additional-tastes,
#makeAcakeStep2_cakeType-4 .additional-tastes,
#makeAcakeStep2_cakeType-3 .additional-tastes,
#makeAcakeStep2_cakeType-2 .additional-tastes {
  display: flex;
  width: 50%;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

#makeAcakeStep2_cakeType-1 .more-tastes,
#makeAcakeStep2_cakeType-5 .more-tastes,
#makeAcakeStep2_cakeType-4 .more-tastes,
#makeAcakeStep2_cakeType-3 .more-tastes,
#makeAcakeStep2_cakeType-2 .more-tastes {
  color: #974558;
  text-decoration: none;
  border-bottom: 1px dashed #974558;
  font-size: 1rem;
  display: block;
  margin-bottom: 10px;
  transition: color 0.25s;
}

#makeAcakeStep2_cakeType-1 .floor-buttons,
#makeAcakeStep2_cakeType-5 .floor-buttons,
#makeAcakeStep2_cakeType-4 .floor-buttons,
#makeAcakeStep2_cakeType-3 .floor-buttons,
#makeAcakeStep2_cakeType-2 .floor-buttons {
  display: flex;
  width: 50%;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

#makeAcakeStep2_cakeType-1 .btn-add-floor,
#makeAcakeStep2_cakeType-5 .btn-add-floor,
#makeAcakeStep2_cakeType-4 .btn-add-floor,
#makeAcakeStep2_cakeType-3 .btn-add-floor,
#makeAcakeStep2_cakeType-2 .btn-add-floor {
  margin-top: 15px;
  margin-bottom: 15px;
}

#makeAcakeStep2_cakeType-1 .tastes div,
#makeAcakeStep2_cakeType-5 .tastes div,
#makeAcakeStep2_cakeType-4 .tastes div,
#makeAcakeStep2_cakeType-3 .tastes div,
#makeAcakeStep2_cakeType-2 .tastes div {
  display: flex;
  width: 14rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  font-size: 0.7rem;
  height: 2rem;
}

#makeAcakeStep2_cakeType-1 .tastes .chocolate,
#makeAcakeStep2_cakeType-5 .tastes .chocolate,
#makeAcakeStep2_cakeType-4 .tastes .chocolate,
#makeAcakeStep2_cakeType-3 .tastes .chocolate,
#makeAcakeStep2_cakeType-2 .tastes .chocolate {
  background-color: #ac6045;
  color: #fff;
}

#makeAcakeStep2_cakeType-1 .tastes .caramel,
#makeAcakeStep2_cakeType-5 .tastes .caramel,
#makeAcakeStep2_cakeType-4 .tastes .caramel,
#makeAcakeStep2_cakeType-3 .tastes .caramel,
#makeAcakeStep2_cakeType-2 .tastes .caramel {
  background-color: #f4cdbe;
  color: #963715;
}

#makeAcakeStep2_cakeType-1 .tastes .banana,
#makeAcakeStep2_cakeType-5 .tastes .banana,
#makeAcakeStep2_cakeType-4 .tastes .banana,
#makeAcakeStep2_cakeType-3 .tastes .banana,
#makeAcakeStep2_cakeType-2 .tastes .banana {
  background-color: #f6de80;
  color: #795548;
}

#makeAcakeStep2_cakeType-1 .tastes .lime,
#makeAcakeStep2_cakeType-5 .tastes .lime,
#makeAcakeStep2_cakeType-4 .tastes .lime,
#makeAcakeStep2_cakeType-3 .tastes .lime,
#makeAcakeStep2_cakeType-2 .tastes .lime {
  background-color: #aae079;
  color: #265100;
}

#makeAcakeStep2_cakeType-1 .tastes .blueberry,
#makeAcakeStep2_cakeType-5 .tastes .blueberry,
#makeAcakeStep2_cakeType-4 .tastes .blueberry,
#makeAcakeStep2_cakeType-3 .tastes .blueberry,
#makeAcakeStep2_cakeType-2 .tastes .blueberry {
  background-color: #797bc9;
  color: #fff;
}

#makeAcakeStep2_cakeType-1 .tastes .rapsberry,
#makeAcakeStep2_cakeType-5 .tastes .rapsberry,
#makeAcakeStep2_cakeType-4 .tastes .rapsberry,
#makeAcakeStep2_cakeType-3 .tastes .rapsberry,
#makeAcakeStep2_cakeType-2 .tastes .rapsberry {
  background-color: #904a9a;
  color: #fff;
}

#makeAcakeStep2_cakeType-1 .tastes .strawberry,
#makeAcakeStep2_cakeType-5 .tastes .strawberry,
#makeAcakeStep2_cakeType-4 .tastes .strawberry,
#makeAcakeStep2_cakeType-3 .tastes .strawberry,
#makeAcakeStep2_cakeType-2 .tastes .strawberry {
  background-color: #d04d4d;
  color: #fff;
}

#makeAcakeStep2_cakeType-2 .floors div,
#makeAcakeStep2_cakeType-5 .floors div,
#makeAcakeStep2_cakeType-4 .floors div,
#makeAcakeStep2_cakeType-3 .floors div {
  display: flex;
  width: 50%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0.25rem;
  color: #fff;
  height: 5rem;
  background-color: #dedede;
}

#makeAcakeStep2_cakeType-2 .floors div div,
#makeAcakeStep2_cakeType-5 .floors div div,
#makeAcakeStep2_cakeType-4 .floors div div,
#makeAcakeStep2_cakeType-3 .floors div div {
  width: 50%;
  height: 100%;
  background-image: none;
  background-color: transparent;
}

#makeAcakeStep2_cakeType-2 .floors div div span,
#makeAcakeStep2_cakeType-5 .floors div div span,
#makeAcakeStep2_cakeType-4 .floors div div span,
#makeAcakeStep2_cakeType-3 .floors div div span {
  width: auto;
  opacity: 0.7;
}

#makeAcakeStep2_cakeType-2 .floors div div:first-of-type,
#makeAcakeStep2_cakeType-5 .floors div div:first-of-type,
#makeAcakeStep2_cakeType-4 .floors div div:first-of-type,
#makeAcakeStep2_cakeType-3 .floors div div:first-of-type {
  border-top-left-radius: 15px;
  border-top-right-radius: 0;
}

#makeAcakeStep2_cakeType-2 .floors div div:last-of-type,
#makeAcakeStep2_cakeType-5 .floors div div:last-of-type,
#makeAcakeStep2_cakeType-4 .floors div div:last-of-type,
#makeAcakeStep2_cakeType-3 .floors div div:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 15px;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-3 .floors div {
    width: 100% !important;
    flex-flow: column nowrap !important;
    justify-content: center !important;
    align-items: center !important;
    height: 10rem !important;
  }
}

#makeAcakeStep2_cakeType-3 .floors div div {
  width: 33%;
  border-radius: 0;
}

#makeAcakeStep2_cakeType-3 .floors div div:first-of-type {
  border-top-left-radius: 15px;
  border-top-right-radius: 0;
}

#makeAcakeStep2_cakeType-3 .floors div div:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 15px;
}

#makeAcakeStep2_cakeType-4 .floors div > div {
  width: 100%;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

#makeAcakeStep2_cakeType-5 .floors {
  display: flex;
  width: 50%;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
}

#makeAcakeStep2_cakeType-5 .floors div > div {
  width: 100%;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-5 .floors {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

#makeAcakeStep2_cakeType-5 .floors .floor:first-of-type {
  display: flex;
  width: 30%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-5 .floors .floor:first-of-type {
    width: 50% !important;
  }
}

#makeAcakeStep2_cakeType-5 .floors .floor:nth-of-type(2) {
  display: flex;
  width: 40%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

@media only screen and (max-width: 700px) {
  #makeAcakeStep2_cakeType-5 .floors .floor:nth-of-type(2) {
    width: 75% !important;
  }
}

#makeAcakeStep2_cakeType-5 .floors .floor:nth-of-type(3) {
  display: flex;
  width: 50%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.conditery article:hover {
  cursor: pointer;
}

.gslide a:not([href]):not([tabindex]) {
  color: #974558;
}

.gslide-title {
  color: #974558 !important;
}

.bulk-items-wrapper .bulk-items .bulk-item-divider {
  font: 1.25rem "Noto Serif Display", serif;
  color: #974558;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: block;
  border-bottom: 1px solid #a57983;
}

.bulk-items-wrapper .bulk-items .bulk-item {
  border-bottom: 1px solid #eaeaea;
  padding: 0.25rem;
}

.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name {
  display: flex;
  align-items: center;
  font-size: 1rem;
  flex-direction: column;
  max-width: 75%;
}

.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name .bulk-item-description {
  font-size: 0.75rem;
  color: #666666;
}

.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name .bulk-item-description p {
  margin-bottom: 0;
}

.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name .bulk-item-taste {
  border: 1px solid #974558;
  color: #974558;
  padding: 0.125rem;
  font-size: 0.65rem;
  text-align: center;
  border-radius: 0.25rem;
}

.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name .fa,
.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name .fas,
.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-name .fab {
  font-size: 0.625rem;
}

.bulk-items-wrapper .bulk-items .bulk-item .bulk-item-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  color: #974558;
  font-weight: bold;
  border-radius: 0.25rem;
}

.category-highlight {
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}

.category-highlight .depth-2 {
  font-size: 1rem !important;
  font-weight: bold;
}

.category-highlight .depth-3 {
  font-size: 0.9rem !important;
  font-weight: bold;
}

.category-highlight .depth-4 {
  font-size: 0.8rem !important;
  font-weight: bold;
}

.btn-is-quickly-made {
  margin: 0.25rem 0 0.75rem 0;
  border-radius: 35rem;
  background-color: #FDBB65;
  padding: 0.25rem 1rem;
  color: white !important;
  font-weight: bold;
  transition: all 0.5s ease;
}

.btn-is-quickly-made:hover {
  transform: scale(1.125);
  background-color: #511826;
}

.modal-quick-cakes {
  font-family: "Noto Serif Display", serif;
  color: #974558;
}

.cake-constructor a {
  color: #974558;
}

.cake-constructor a:hover,
.cake-constructor a:focus {
  text-decoration: none;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .close svg {
    max-width: 3rem;
  }
}

.cake-constructor .modal-dialog {
  max-width: 60rem;
  width: 60rem;
}

@media only screen and (max-width: 1024px) {
  .cake-constructor .modal-dialog {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog {
    width: auto;
  }
}

.cake-constructor .modal-dialog .modal-header {
  background-color: #FDF1F3;
  flex-direction: row;
  padding: 0.75rem 2rem;
  background-image: url("../assets/img/bg-cake-constructor-modal.png");
  background-position: center center;
  background-size: cover;
}

.cake-constructor .modal-dialog .modal-header .cake-constuctor-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.cake-constructor .modal-dialog .modal-header .cake-constuctor-title .modal-title {
  color: #974558;
  font-size: 2.125rem;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-header .cake-constuctor-title .modal-title {
    line-height: 100%;
  }
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper {
  width: 100%;
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress {
  list-style: none;
  display: inline-flex;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
  color: #974558;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress {
    gap: 0.25rem;
    font-size: 0.75rem;
    line-height: 100%;
  }
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress .cake-constructor-progress-step {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress .cake-constructor-progress-step::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 100%;
  position: relative;
  background-image: url("data:image/svg+xml, %3Csvg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16.407L1.65421 18L11 9L1.65421 0L0 1.593L7.69159 9L0 16.407Z' fill='%23DFB2BC'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.325rem;
  background-position: center;
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress .cake-constructor-progress-step:last-child::after {
  content: none;
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress .active-step {
  color: #551920;
  font-weight: bold;
}

.cake-constructor .modal-dialog .modal-header .cake-constructor-progress-wrapper .cake-constructor-progress .active-step a {
  color: #551920;
}

.cake-constructor .modal-dialog .modal-content {
  padding: 0;
}

.cake-constructor .modal-dialog .modal-content .modal-body {
  padding: 0.75rem 2rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-steps-wrapper {
  padding: 1.5rem 1rem;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-types-grid {
    grid-template-columns: 100%;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: start;
  text-align: center;
  width: 100%;
  transition: all ease-in-out 0.25s;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake .cake-preview-image {
  width: 100%;
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid transparent;
  box-shadow: 0 0.325rem 0.75rem rgba(227, 227, 227, 0.4705882353);
  transition: all ease-in-out 0.25s;
}

@media only screen and (max-width: 992px) {
  .cake-constructor .modal-dialog .modal-content .cake-types-grid .cake .cake-preview-image {
    height: 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-types-grid .cake .cake-preview-image {
    height: 15rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake .cake-preview-image img {
  width: 65%;
  transition: all ease-in-out 0.25s;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake .cake-title {
  font-family: "Noto Serif Display", serif;
  color: #551920;
  margin-bottom: 0;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake .cake-description {
  color: #686868;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake:hover {
  transform: scale(1.025);
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake:hover .cake-preview-image {
  border: 2px solid #FFD57E;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake:hover .cake-preview-image img {
  width: 75%;
}

.cake-constructor .modal-dialog .modal-content .cake-types-grid .cake:hover .cake-title {
  color: #9F6B00;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}

@media only screen and (max-width: 992px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder {
    grid-template-columns: 100%;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization {
  background-color: #FCFCFC;
  border: 3px solid #F0F0F0;
  box-shadow: 0 0.5rem 1.5rem rgba(151, 151, 151, 0.1294117647);
  padding: 1rem;
  border-radius: 1.75rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 992px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization {
    max-width: 75%;
    margin: 1rem auto;
  }
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization {
    max-width: 100%;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-header .cake-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.125rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-header .cake-heading .cake-title {
  color: #551920;
  font-family: "Noto Serif Display", serif;
  font-size: 1.725rem;
  margin-bottom: 0;
  line-height: 1;
}

@media only screen and (max-width: 1024px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-header .cake-heading .cake-title {
    font-size: 1.25rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-header .cake-heading .cake-total-weight {
  color: #686868;
  font-family: "Noto Serif Display", serif;
  font-size: 1rem;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-header .cake-heading .cake-total-weight {
    font-size: 0.75rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 22.5rem;
  padding: 1rem;
  margin: 1rem 0;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render {
  transition: all ease-in-out 0.25s;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render svg {
  max-width: 100%;
  height: fit-content;
  display: block;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render svg path,
.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render svg ellipse {
  transition: all ease-in-out 0.25s;
  stroke-dasharray: 5;
  stroke-width: 2px;
  stroke: transparent;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render:hover svg path,
.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render:hover svg ellipse {
  stroke: #fd00ff;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(1) {
  max-width: 90%;
  margin-top: -3rem;
  z-index: 0;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(1) {
    margin-top: -2rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(2) {
  max-width: 70%;
  margin-top: -2rem;
  z-index: 1;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(2) {
    margin-top: -1.5rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(3) {
  max-width: 50%;
  margin-top: -1rem;
  z-index: 2;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(3) {
    margin-top: -0.75rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(4) {
  max-width: 30%;
  margin-top: -0.5rem;
  z-index: 3;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(4) {
    margin-top: -0.25rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-round:nth-child(5) {
  max-width: 15%;
  z-index: 4;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(1) {
  max-width: 90%;
  margin-top: -7.5rem;
  z-index: 0;
}

@media only screen and (max-width: 1024px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(1) {
    margin-top: -6rem;
  }
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(1) {
    margin-top: -4.25rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(2) {
  max-width: 70%;
  margin-top: -5.325rem;
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(2) {
    margin-top: -4.325rem;
  }
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(2) {
    margin-top: -4.25rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(3) {
  max-width: 50%;
  margin-top: -3.25rem;
  z-index: 2;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(3) {
    margin-top: -3.025rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(4) {
  max-width: 30%;
  margin-top: -1.565rem;
  z-index: 3;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(4) {
    margin-top: -2.025rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(5) {
  max-width: 15%;
  z-index: 4;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-square:nth-child(5) {
    margin-top: -0.8565rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom a {
  transition: all ease-in-out 0.25s;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom a svg path,
.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom a svg ellipse {
  stroke-dasharray: 0;
  stroke-width: 1px;
  stroke: #d5d5d5;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom a:hover svg path,
.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom a:hover svg ellipse {
  stroke-dasharray: 0;
  stroke-width: 1px;
  stroke: #07d900;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom .floors-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom .attached-filepath .attached-file {
  display: flex;
  gap: 0.5rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom .attached-filepath .attached-file svg path,
.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-visualization .cake-constructor-render .floor-render.floor-render-custom .attached-filepath .attached-file svg ellipse {
  stroke-dasharray: 0;
  stroke-width: 0;
  stroke: #333333;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-composition .cake-composition-title {
  color: #686868;
  font-size: 1.25rem;
  font-family: "Noto Serif Display", serif;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-composition .cake-composition-description {
  color: #202020;
  font-weight: 200;
  font-size: 0.65rem;
  max-height: 3.5rem;
  overflow-y: scroll;
}

@media only screen and (max-width: 1024px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-composition .cake-composition-description {
    max-height: unset;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings {
  padding: 1rem;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .cake-constructor-cake-wrapper {
    text-align: center;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .cake-constructor-cake-wrapper .cake-constructor-cake-title {
  color: #974558;
  font-size: 2rem;
  font-family: "Noto Serif Display", serif;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .cake-constructor-cake-wrapper .cake-constructor-cake-description {
  color: #686868;
  font-size: 1rem;
  font-weight: 300;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes {
  display: block;
  border-bottom: 1px solid #EAEAEA;
  padding: 0.5rem 0;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  transition: all ease-in-out 0.25s;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes.floor-active {
  background-color: #fffff8;
  padding: 0.5rem 0;
  padding-bottom: 2.5rem;
  border-bottom-color: transparent;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes:first-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 2.5rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-title-wrapper {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-buttons .btn-taste {
  font-weight: 300;
  line-height: 1;
  color: #000;
  border-color: #000;
  font-size: 0.685rem;
  cursor: pointer;
  margin-bottom: 0;
  width: 32.5%;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-buttons .btn-taste {
    width: 31.5%;
  }
}

@media only screen and (max-width: 575px) {
  .cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-buttons .btn-taste {
    width: 48.95%;
  }
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .floor-tastes .floor-taste-buttons .btn-taste.active {
  font-weight: bold;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .cart-wrapper .btn {
  width: 100%;
  background-color: #8B3A3A;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.333rem;
  padding: 0.75rem;
  height: auto;
  border: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  transition: all ease-in-out 0.25s;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .cake-constructor-settings .cart-wrapper .btn:hover {
  background-color: #974558;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .noUi-target {
  background: #F0F0F0 !important;
  border-radius: 3rem !important;
  border: 0 !important;
  box-shadow: none !important;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .noUi-horizontal {
  height: 10px !important;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .noUi-horizontal .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 10rem !important;
  background-color: #974558 !important;
  box-shadow: none !important;
  border: none !important;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .noUi-horizontal .noUi-handle::before,
.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .noUi-horizontal .noUi-handle::after {
  content: none !important;
}

.cake-constructor .modal-dialog .modal-content .cake-constructor-builder .noUi-tooltip {
  border: 0 !important;
  border-radius: 0.25rem !important;
  background: rgba(0, 0, 0, 0.8117647059) !important;
  color: #fff !important;
  font-weight: 900 !important;
  padding: 0.25rem 0.5rem !important;
}

.cake-mini-preview {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  max-width: 100%;
}

.cake-mini-preview svg {
  max-width: 100%;
  height: fit-content;
  display: block;
}

.cake-mini-preview svg path,
.cake-mini-preview svg ellipse {
  transition: all ease-in-out 0.25s;
  stroke-dasharray: 5;
  stroke-width: 2px;
  stroke: transparent;
}

.cake-mini-preview:hover svg path,
.cake-mini-preview:hover svg ellipse {
  stroke: #ffffff;
  opacity: 0.95;
}

.cake-mini-preview .floor-render-round {
  position: relative;
}

.cake-mini-preview .floor-render-round:nth-child(1) {
  max-width: 90%;
  top: -1rem;
  z-index: 0;
}

.cake-mini-preview .floor-render-round:nth-child(2) {
  max-width: 70%;
  top: 0rem;
  z-index: 1;
}

.cake-mini-preview .floor-render-round:nth-child(3) {
  max-width: 50%;
  top: 0.75rem;
  z-index: 2;
}

.cake-mini-preview .floor-render-round:nth-child(4) {
  max-width: 30%;
  top: 1.25rem;
  z-index: 3;
}

.cake-mini-preview .floor-render-round:nth-child(5) {
  max-width: 15%;
  top: 1.5rem;
  z-index: 4;
}

.cake-mini-preview .floor-render-square {
  position: relative;
}

.cake-mini-preview .floor-render-square:nth-child(1) {
  max-width: 90%;
  top: 0rem;
  z-index: 0;
}

.cake-mini-preview .floor-render-square:nth-child(2) {
  max-width: 70%;
  top: 2.757rem;
  z-index: 1;
}

.cake-mini-preview .floor-render-square:nth-child(3) {
  max-width: 50%;
  top: 4.75rem;
  z-index: 2;
}

.cake-mini-preview .floor-render-square:nth-child(4) {
  max-width: 30%;
  top: 6rem;
  z-index: 3;
}

.cake-mini-preview .floor-render-square:nth-child(5) {
  max-width: 15%;
  top: 6.65rem;
  z-index: 4;
}

.cake-mini-preview .floor-render-custom svg {
  max-width: 4rem;
}

.cake-tastes-list .badge {
  border-color: #000000;
  color: #000000;
}

.cake-files {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
}

.cake-files .cake-file {
  display: flex;
  align-items: center;
  color: #647F94;
  text-decoration: none;
}

.cake-files .cake-file svg {
  max-height: 1rem;
  max-width: 1rem;
}

.page-product .product-content .product-gallery {
  width: 100%;
  height: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #FDF9FB;
}

.page-product .product-content .product-header {
  padding: 3rem 3rem;
  border-bottom: 1px solid #FDF9FB;
  font-size: 0.75rem;
  color: #626262;
}

.page-product .product-content .product-header a {
  color: #974558;
}

.page-product .product-content .product-header .product-title-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
}

.page-product .product-content .product-header .product-title-wrapper .product-back-link {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #808080;
}

.page-product .product-content .product-header .product-title-wrapper .product-back-link svg {
  margin-top: 0.125rem;
}

.page-product .product-content .product-header .product-title-wrapper .product-title {
  font-family: "Noto Serif Display", serif;
  color: #501826;
}

.page-product .product-content .product-header .product-attributes-wrapper {
  display: grid;
  grid-template-columns: 10% 20% 10% 15% auto auto;
  align-items: center;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-attribute {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-attribute .btn-is-quickly-made {
  margin: 0;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-views svg {
  color: #BFBFBF;
  margin-right: 0.25rem;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-allergen a {
  display: flex;
  align-items: center;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-allergen a svg {
  margin: 0.25rem;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-categories {
  justify-content: end;
}

.page-product .product-content .product-header .product-attributes-wrapper .product-categories .btn {
  margin: 0.125rem;
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  border: 1px solid #d4a7b2;
  border-radius: 1rem;
}

.page-product .product-content .product-description {
  padding: 4rem 3rem;
  font-weight: lighter;
}

.page-product .product-content .product-cart {
  padding: 2rem 3rem;
  display: flex;
  align-items: center;
}

.page-product .product-content .product-cart .product-price {
  font-family: "Noto Serif Display", serif;
  color: #000000;
  font-weight: 500;
  margin-right: 1rem;
  font-size: 1.5rem;
}

.page-product .similar-products {
  padding: 1rem 5rem 5rem;
}

.page-product .similar-products .similar-products-title {
  font-family: "Noto Serif Display", serif;
  color: #501826;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.page-product .similar-products .similar-product-item img {
  border-radius: 1.25rem;
}

.page-product .similar-products .similar-product-item .similar-product-title {
  font-family: "Noto Serif Display", serif;
  display: flex;
  gap: 0.25rem;
}

.page-product .similar-products .similar-product-item .similar-product-title svg {
  max-width: 1.5rem;
}

.page-product .similar-products .similar-product-item .similar-product-description {
  font-size: 0.75rem;
  color: #626262;
  min-height: 5.25rem;
}

.page-product .similar-products .similar-product-item a {
  color: #974558;
}

@media only screen and (max-width: 1024px) {
  .page-product .product-content .product-header .product-title {
    font-size: 2rem;
  }

  .page-product .product-content .product-header .product-attributes-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .page-product .product-content .product-header .product-attributes-wrapper .product-categories {
    justify-content: start;
  }
}

@media only screen and (max-width: 575px) {
  .page-product .product-content .product-gallery {
    height: auto;
  }

  .page-product .product-content .product-header .product-title-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .page-product .product-content .product-header .product-title {
    font-size: 2rem;
  }

  .page-product .product-content .product-header .product-attributes-wrapper {
    grid-template-columns: 100%;
    gap: 1rem;
  }

  .page-product .product-content .product-cart {
    flex-direction: column;
    align-items: center;
  }

  .page-product .product-content .similar-products {
    padding: 1rem 3rem 0rem;
  }
}

.filial-button {
  background-color: #ffe8da;
  padding: 0.25rem 2rem;
  border-radius: 0.5rem;
}

.cart-order-section {
  background: white;
}

@media only screen and (max-width: 768px) {
  .cart {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.cart-title {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  color: #511826;
}

@media only screen and (max-width: 575px) {
  .cart-title [class*=col-] {
    padding-left: 0;
  }
}

.cart-title h1 {
  font: 2.5rem "Noto Serif Display", serif;
}

@media only screen and (max-width: 768px) {
  .cart-title [class*=col-]:last-child {
    display: none;
  }
}

.cart-content {
  color: #626262;
  padding-bottom: 1rem;
}

.cart-content .btn-primary {
  border-width: 0;
  color: #462D2A;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
}

.cart-content:last-child {
  padding-bottom: 0;
}

.cart-content [class*=col-] {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  overflow: initial;
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-] {
    height: auto;
  }
}

.cart-content [class*=col-] .form-control,
.cart-content [class*=col-] #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart-content [class*=col-] .form-control-file {
  display: inline-block;
  width: 4rem;
  border-radius: 1rem;
}

.cart-content [class*=col-] .select-form-control {
  width: 10rem;
}

.cart-content [class*=col-]:first-child {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.cart-content [class*=col-]:first-child img {
  width: 75%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-]:first-child {
    height: 10rem;
  }
}

.cart-content [class*=col-]:nth-child(2) {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-]:nth-child(2) {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem 0;
  }
}

.cart-content [class*=col-]:nth-child(2) h3 {
  font: 1.25rem "Noto Serif Display", serif;
  color: #551920;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.cart-content [class*=col-]:nth-child(2) h3 svg {
  max-width: 1rem;
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-]:nth-child(3) {
    display: flex;
    width: 25%;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-]:nth-child(4) {
    display: flex;
    width: 35%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-]:nth-child(5) {
    display: flex;
    width: 25%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .cart-content [class*=col-]:nth-child(6) {
    display: flex;
    width: 15%;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}

.cart-content .form-control,
.cart-content #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart-content .form-control-file,
.cart-details .form-control,
.cart-details #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart-details .form-control-file {
  width: 60%;
  box-shadow: inset 0px 0px 30px 0px #dedede;
  border: 0;
  height: 2rem;
  font-size: 0.8rem;
}

.cart-summary {
  width: 100%;
  color: #511826;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
}

.cart-summary h4 {
  font-size: 1rem;
}

.cart-details {
  color: #43373a;
}

.cart-details h2 {
  font: 1.25rem "Noto Serif Display", serif;
  color: #511826;
}

.cart-details .form-control,
.cart-details #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart-details .form-control-file {
  width: 90%;
}

@media only screen and (max-width: 768px) {
  .cart-details .form-control,
  .cart-details #makeAcakeStep1 .form-control-file,
  #makeAcakeStep1 .cart-details .form-control-file {
    width: 100%;
  }
}

.cart-details .form-group textarea {
  height: 5rem;
}

.cart-details .btn-primary {
  width: 100%;
}

.cart-details .btn-primary.active,
.cart-details .btn-primary.focus {
  background: #974558;
  color: #fff;
}

@media only screen and (max-width: 375px) {
  .cart-details .btn-primary {
    width: 100% !important;
  }
}

.cart-details .btn-primary:hover {
  background-color: #974558;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .cart3 {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.cart3-title {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  color: #511826;
}

@media only screen and (max-width: 575px) {
  .cart3-title {
    text-align: center;
  }
}

.cart3-title h1 {
  font: 2.5rem "Noto Serif Display", serif;
}

@media only screen and (max-width: 768px) {
  .cart3-title [class*=col-]:last-child {
    display: none;
  }
}

.cart3-content {
  font-size: 0.7rem;
  color: #626262;
}

.cart3-content [class*=col-] {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  height: 5rem;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .cart3-content [class*=col-] {
    height: auto;
  }
}

.cart3-content [class*=col-] .form-control,
.cart3-content [class*=col-] #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart3-content [class*=col-] .form-control-file {
  display: inline-block;
  width: 2rem;
  height: 1.5rem;
  border-radius: 0;
}

.cart3-content [class*=col-]:nth-child(1) {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 575px) {
  .cart3-content [class*=col-]:nth-child(1) {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem 0;
  }
}

.cart3-content [class*=col-]:nth-child(1) h3 {
  font: 0.9rem "Inter", sans-serif;
  color: #974558;
  font-style: italic;
}

@media only screen and (max-width: 575px) {
  .cart3-content [class*=col-]:nth-child(3) {
    display: flex;
    width: 25%;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .cart3-content [class*=col-]:nth-child(4) {
    display: flex;
    width: 35%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .cart3-content [class*=col-]:nth-child(5) {
    display: flex;
    width: 25%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .cart3-content [class*=col-]:nth-child(6) {
    display: flex;
    width: 15%;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}

.cart3-content .form-control,
.cart3-content #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart3-content .form-control-file,
.cart3-details .form-control,
.cart3-details #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart3-details .form-control-file {
  width: 60%;
  box-shadow: inset 0px 0px 30px 0px #dedede;
  border: 0;
  height: 2rem;
  font-size: 0.6rem;
}

.cart3-summary {
  width: 100%;
  font-size: 0.7rem;
  color: #511826;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
}

.cart3-summary h4 {
  font-size: 1rem;
}

.cart3-details {
  font-size: 0.7rem;
  color: #43373a;
}

.cart3-details h2 {
  font: 1.25rem "Noto Serif Display", serif;
  color: #511826;
}

.cart3-details .form-control,
.cart3-details #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .cart3-details .form-control-file {
  width: 90%;
}

@media only screen and (max-width: 768px) {
  .cart3-details .form-control,
  .cart3-details #makeAcakeStep1 .form-control-file,
  #makeAcakeStep1 .cart3-details .form-control-file {
    width: 100%;
  }
}

.cart3-details .form-group textarea {
  height: 5rem;
}

.cart3-details .btn-primary {
  border-radius: 0;
  border: 2px solid #974558;
  color: #974558;
  font-size: 0.7rem;
  background: #fff;
  width: 100%;
}

.cart3-details .btn-primary:hover {
  background-color: #974558;
  color: #fff;
  cursor: pointer;
}

.invalid-feedback {
  background-color: #F44336;
  display: block;
  padding: 0.125rem 0.5rem;
  color: #fff;
}

.order-time-notice {
  color: #c12042;
  font: 1.25rem "Noto Serif Display", serif;
  background: #ffe9bd;
  padding: 1rem;
  border-radius: 0.5rem;
}

.order-bh-notice {
  width: 90%;
  border-radius: 0.25rem;
  border: 2px solid #984658;
  color: #984658;
  padding: 0.5rem 1rem;
}

.order-bh-notice .btn {
  border-radius: 0.25rem;
}

.order-no-time-notice {
  width: 90%;
  font-size: 0.65rem;
  border-radius: 0.25rem;
  border: 2px solid #fdbb64;
  color: #fdbb64;
  padding: 0.5rem 1rem;
}

.shop-card {
  min-height: 10.5rem;
  margin-bottom: 1rem;
  border-color: #e8d5d9;
}

.shop-card .shop-title {
  font: 1rem "Noto Serif Display", serif;
  color: #511826;
  margin-bottom: 0.333rem;
}

.shop-card a {
  color: #b97d2d;
}

.delivery {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 150px;
  bottom: 3px;
  left: calc(50% - 2px);
  content: " ";
  display: block;
  background: #4caf50;
}

.delivery.busy {
  background: #f64747;
}

@media only screen and (max-width: 575px) {
  .cart-details-v2 .order-delivery {
    order: 1;
  }
}

@media only screen and (max-width: 575px) {
  .cart-details-v2 .order-calendar {
    order: 2;
  }
}

@media only screen and (max-width: 575px) {
  .cart-details-v2 .order-details {
    order: 3;
  }
}

.cart-details-v2 .delivery-type,
.cart-details-v2 .payment-type,
.cart-details-v2 .delivery-options-box,
.cart-details-v2 .payment-options-box {
  margin-bottom: 1.5rem;
}

.cart-details-v2 .filial-button {
  width: 90%;
  padding: 0.75rem 2.5rem;
  margin-bottom: 1rem;
}

.cart-details-v2 .filial-button p {
  margin-bottom: 0;
}

.cart-details-v2 .flatpickr-calendar {
  box-shadow: none;
  border: 0;
}

.cart-details-v2 .flatpickr-calendar .flatpickr-day.selected {
  background: #511826;
  border-color: #511826;
}

.cart-details-v2 .timeslots-wrapper {
  padding: 0 0.125rem;
  overflow-y: scroll;
  height: 10.85rem;
}

.cart-details-v2 .timeslots-wrapper .btn {
  flex: unset !important;
  width: 3rem !important;
}

.cart-details-v2 .timeslots-wrapper .btn:first-child,
.cart-details-v2 .timeslots-wrapper .btn:last-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cart-details-v2 .btn-selectors-group {
  padding: 0.25rem 0;
  flex-wrap: wrap;
}

.cart-details-v2 .btn-selectors-group .btn {
  width: fit-content;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  flex: auto;
}

.cart-details-v2 .btn-selectors-group .btn:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.cart-details-v2 .btn-selectors-group .btn:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.cart-details-v2 .btn-selectors-group .btn.active {
  z-index: 0;
}

.cart-details-v2 .btn-selectors-group .btn:first-child {
  margin-left: -1px;
}

.cart-details-v2 .order-bh-notice,
.cart-details-v2 .order-no-time-notice {
  width: 100%;
}

.cart-details-v2 textarea {
  height: inherit !important;
}

.page-auth {
  background-image: url("../assets/img/bg-page-auth.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-auth .form {
  min-height: 80vh;
}

.page-auth .form a {
  color: #8D4A58;
}

.page-auth .form .form-action-link {
  font-size: 0.85rem;
}

.page-auth .form .card {
  border: 0;
  border-radius: 0;
  min-width: 50%;
  padding: 2rem 4rem 1rem;
  border-radius: 2rem;
}

@media only screen and (max-width: 575px) {
  .page-auth .form .card {
    padding: 1rem 2rem 1rem;
  }
}

.page-auth .form .card .form-header {
  text-align: center;
  margin-bottom: 2rem;
}

.page-auth .form .card .form-header .form-title {
  color: #4A1C26;
  font-family: "Noto Serif Display", serif;
}

.page-auth .form .col-form-label {
  font-size: 0.75rem;
}

.page-auth .form .form-control,
.page-auth .form #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .page-auth .form .form-control-file {
  box-shadow: inset 0px 0px 30px 0px #dedede;
  border: 0;
  border-radius: 0;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
}

.page-restore-password .form-restore-pass-reset .card {
  max-width: 65%;
  padding: 3rem 5rem 2rem;
}

@media only screen and (max-width: 575px) {
  .page-restore-password .form-restore-pass-reset .card {
    max-width: 100%;
    padding: 2rem 1rem 2rem;
  }
}

.page-restore-password .form-restore-pass-reset .card .form-wrapper {
  padding: 0rem 4rem;
}

@media only screen and (max-width: 575px) {
  .page-restore-password .form-restore-pass-reset .card .form-wrapper {
    padding: 0rem 1rem;
  }
}

.page-restore-password .form-restore-pass-reset .card .form-wrapper .btn {
  width: 100%;
}

.page-profile {
  background-image: url("../assets/img/bg-page-auth.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-profile .profile {
  min-height: 80vh;
  width: 80vw;
  margin-left: auto;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile {
    min-height: 100%;
    width: 100%;
    margin-left: 0;
  }
}

.page-profile .profile a {
  color: #8D4A58;
}

.page-profile .profile h1,
.page-profile .profile h2,
.page-profile .profile h3 {
  font-family: "Noto Serif Display", serif;
}

.page-profile .profile h2 {
  color: #974558;
  font-size: 1.25rem;
}

.page-profile .profile h3 {
  font: 0.9rem "Inter", sans-serif;
  color: #974558;
  font-style: italic;
}

.page-profile .profile .form-action-link {
  font-size: 0.85rem;
}

.page-profile .profile .card {
  border: 0;
  border-radius: 0;
  min-width: 80vw;
  padding: 2rem 4rem 1rem;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile .card {
    min-width: 100%;
    padding: 2rem 2rem 1rem;
  }
}

.page-profile .profile .card .form-header {
  margin-bottom: 1rem;
}

.page-profile .profile .card .form-header .form-title {
  color: #4A1C26;
  font-family: "Noto Serif Display", serif;
}

.page-profile .profile .col-form-label {
  font-size: 0.75rem;
}

.page-profile .profile .form-control,
.page-profile .profile #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .page-profile .profile .form-control-file {
  box-shadow: inset 0px 0px 30px 0px #dedede;
  border: 0;
  border-radius: 0;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
}

.page-profile .profile .nav-pills {
  margin-bottom: 1rem;
  gap: 0.5rem;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile .nav-pills {
    flex-direction: column;
  }
}

.page-profile .profile .nav-pills .nav-link {
  color: #974558;
  background-color: transparent;
  border-radius: 0;
  transition: all 0.25s ease-in-out;
  border-radius: 35rem;
}

.page-profile .profile .nav-pills .nav-link.active,
.page-profile .profile .nav-pills .nav-link:hover {
  background-color: #974558 !important;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile .nav-pills .nav-link {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    border-width: 2px;
  }
}

.page-profile .profile .nav-pills .nav-item-danger {
  margin-left: auto;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile .nav-pills .nav-item-danger {
    margin-left: 0;
  }
}

.page-profile .profile .nav-pills .nav-link-danger {
  border-color: #e91e63;
  color: #e91e63;
}

.page-profile .profile .nav-pills .nav-link-danger:hover {
  background-color: #e91e63 !important;
  color: #ffffff;
}

.page-profile .profile .orders-info-text {
  color: #A0A0A0;
  width: 50%;
  font-size: 0.75rem;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile .orders-info-text {
    width: 100%;
  }
}

.page-profile .profile .badge-order-status {
  font-weight: 400;
  width: 75%;
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  background-color: #616161;
  color: #000000;
}

@media only screen and (max-width: 575px) {
  .page-profile .profile .badge-order-status {
    width: 100%;
  }
}

.page-profile .profile .badge-order-status.badge-order-status-new {
  background-color: #C9F2F8;
  color: #002071;
}

.page-profile .profile .badge-order-status.badge-order-status-confirmed {
  background-color: #CAF8C9;
  color: #007120;
}

.page-profile .profile .badge-order-status.badge-order-status-cancelled {
  background-color: #F8C9C9;
  color: #710000;
}

.page-profile .profile .table {
  font-size: 0.75rem;
}

.page-profile .profile .table.table-md th,
.page-profile .profile .table.table-md td {
  padding: 0.5rem 0.75rem;
  vertical-align: top;
}

.page-profile .profile .table thead {
  background-color: #FAFAFA;
  color: #616161;
}

.page-profile .profile .table th {
  border-top: 0;
  border-bottom: 0;
  font-weight: 400;
}

.page-profile .profile-order-preview .card .form-header {
  margin-bottom: 2rem;
}

.blog {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.6rem;
}

.blog span {
  width: 100%;
}

.blog article {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 0.25rem;
}

.blog article p {
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .blog article {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.blog article:hover {
  background-color: #f7f7f7;
}

.blog article div {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .blog article div {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .blog article div {
    padding-left: 0 !important;
  }
}

.blog article div div {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.blog article div div span {
  width: 50%;
}

.blog article div div span:last-of-type {
  text-align: right;
}

@media only screen and (max-width: 575px) {
  .blog article div div span:last-of-type {
    text-align: left;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .blog article div div span {
    width: 100%;
    text-align: left;
  }
}

.blog article h3 {
  font: 1.3rem "Noto Serif Display", serif;
  color: #9f6b00;
}

@media only screen and (max-width: 768px) {
  .blog article h3 {
    margin-top: 1rem;
  }
}

.blog article p {
  font-size: 0.7rem;
}

.blog article img {
  max-width: 100%;
  border-radius: 0.25rem;
}

.blog-single {
  font-size: 0.7rem;
  color: #43373a;
}

.blog-single .title {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .blog-single .title {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .blog-single .title {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .blog-single .title span {
    width: 50%;
  }

  .blog-single .title span:last-of-type {
    text-align: right;
  }
}

.blog-single a {
  color: #9f6b00;
}

.blog-single a:hover {
  color: #ffbe5e;
  text-decoration: none;
}

.blog-single h1,
.blog-single h2,
.blog-single h3 {
  font: 2.5rem "Noto Serif Display", serif;
  color: #511826;
}

@media only screen and (max-width: 400px) {
  .blog-single h1,
  .blog-single h2,
  .blog-single h3 {
    text-align: center;
  }
}

.blog-single h2 {
  font-size: 1.25rem;
}

.blog-single h3 {
  font-size: 1rem;
  color: #974558;
}

.blog-single .btn-secondary {
  border-radius: 0rem;
  border: 2px solid #974558;
  color: #974558;
  font-size: 0.6rem;
  background: #fff;
}

.blog-single .btn-secondary:hover {
  background-color: #974558;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 400px) {
  .blog-single .btn-secondary {
    margin-top: 1rem;
  }
}

.footer,
.footer-v2 {
  background-color: #732839;
  padding: 3rem 3rem;
}

.footer .footer-logo,
.footer-v2 .footer-logo {
  width: 16.5rem;
}

.footer *,
.footer-v2 * {
  color: #FDF9FB;
}

@media only screen and (max-width: 575px) {
  .footer *,
  .footer-v2 * {
    gap: 1rem;
  }
}

.footer a,
.footer-v2 a {
  color: #E3B2CC;
  text-decoration: none;
  transition: all 0.25s ease-out;
}

.footer a:hover,
.footer-v2 a:hover {
  color: #FFF;
}

.footer .container-fluid,
.footer .container,
.footer-v2 .container-fluid,
.footer-v2 .container {
  display: grid;
  gap: 2rem;
}

.footer .footer-title,
.footer-v2 .footer-title {
  font-family: "Noto Serif Display", serif;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 1024px) {
  .footer .footer-title,
  .footer-v2 .footer-title {
    font-size: 1.25rem;
  }
}

.footer .form-control,
.footer #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .footer .form-control-file,
.footer-v2 .form-control,
.footer-v2 #makeAcakeStep1 .form-control-file,
#makeAcakeStep1 .footer-v2 .form-control-file {
  border: 1px solid #E3B2CC;
  background-color: transparent;
  padding: 0.25rem 1.75rem;
  border-radius: 0.5rem;
  color: #FDF9FB;
  transition: all 0.25s ease-out;
}

.footer .form-control::placeholder,
.footer #makeAcakeStep1 .form-control-file::placeholder,
#makeAcakeStep1 .footer .form-control-file::placeholder,
.footer-v2 .form-control::placeholder,
.footer-v2 #makeAcakeStep1 .form-control-file::placeholder,
#makeAcakeStep1 .footer-v2 .form-control-file::placeholder {
  opacity: 0.5;
  color: #E3B2CC;
}

.footer .form-control:focus,
.footer #makeAcakeStep1 .form-control-file:focus,
#makeAcakeStep1 .footer .form-control-file:focus,
.footer-v2 .form-control:focus,
.footer-v2 #makeAcakeStep1 .form-control-file:focus,
#makeAcakeStep1 .footer-v2 .form-control-file:focus {
  background-color: #732839;
  transform: scale(1.125);
}

.footer .btn,
.footer-v2 .btn {
  border-radius: 0.5rem;
}

.footer .btn-pink,
.footer-v2 .btn-pink {
  background-color: #551920;
  border-color: #551920;
  color: #FFF;
  transition: all 0.25s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer .btn-pink:hover,
.footer-v2 .btn-pink:hover {
  background-color: #F63349;
  border-color: #F63349;
}

.footer .footer-nav,
.footer-v2 .footer-nav {
  margin-bottom: 0;
  padding-left: 0.75rem;
  list-style-type: "- ";
}

@media only screen and (max-width: 575px) {
  .footer .footer-col-subscribe,
  .footer-v2 .footer-col-subscribe {
    flex-direction: column;
  }
}

.footer .newsletter-form,
.footer-v2 .newsletter-form {
  display: flex;
  gap: 0.25rem;
}

.footer .row-1st .footer-col,
.footer-v2 .row-1st .footer-col {
  display: flex;
  align-items: center;
}

.footer .row-1st .footer-title,
.footer-v2 .row-1st .footer-title {
  margin-bottom: 0;
}

.footer .footer-socials a,
.footer-v2 .footer-socials a {
  color: #FFF;
  transition: all 0.25s ease-out;
}

.footer .footer-socials a:hover,
.footer-v2 .footer-socials a:hover {
  color: #CC4E06;
}

