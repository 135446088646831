.page-product
  .product-content
    .product-gallery
      width: 100%
      height: 35rem
      display: flex
      justify-content: center
      align-items: center
      overflow: hidden
      background-color: $color--light-pink

    .product-header
      padding: 3rem 3rem
      border-bottom: 1px solid $color--light-pink
      font-size: .75rem
      color: #626262
      a
        color: #974558
      .product-title-wrapper
        display: grid
        grid-template-columns: auto auto
        align-items: start
        .product-back-link
          display: flex
          align-items: center
          justify-content: end
          color: #808080
          svg
            margin-top: .125rem
        .product-title
          font-family: $cursive
          color: #501826
      .product-attributes-wrapper
        display: grid
        grid-template-columns: 10% 20% 10% 15% auto auto
        align-items: center
        // justify-content: space-between
        .product-attribute
          display: flex
          align-items: start
          flex-wrap: wrap
          .btn-is-quickly-made
            margin: 0
        .product-views
          svg
            color: #BFBFBF
            margin-right: .25rem
        .product-allergen
          a
            display: flex
            align-items: center
            svg
              margin: .25rem
        .product-categories
          justify-content: end
          .btn
            margin: .125rem
            padding: .25rem 1rem
            font-size: .75rem
            border: 1px solid #d4a7b2
            border-radius: 1rem

    .product-description
      padding: 4rem 3rem
      font-weight: lighter

    .product-cart
      padding: 2rem 3rem
      display: flex
      align-items: center
      .product-price
        font-family: $cursive
        color: #000000
        font-weight: 500
        margin-right: 1rem
        font-size: 1.5rem

  .similar-products
    padding: 1rem 5rem 5rem
    .similar-products-title
      font-family: $cursive
      color: #501826
      margin-bottom: 2rem
      font-size: 2.5rem
    .similar-product-item
      img
        border-radius: 1.25rem
      .similar-product-title
        font-family: $cursive
        display: flex
        gap: .25rem
        svg
          max-width: 1.5rem
      .similar-product-description
        font-size: .75rem
        color: #626262
        min-height: 5.25rem
      a
        color: #974558


@media only screen and (max-width: 1024px)
  .page-product
    .product-content
      .product-header
        .product-title
          font-size: 2rem
        .product-attributes-wrapper
          display: grid
          grid-template-columns: 50% 50%
          .product-categories
            justify-content: start

@media only screen and (max-width: 575px)
  .page-product
    .product-content
      .product-gallery
        height: auto
      .product-header
        .product-title-wrapper
          display: flex
          flex-direction: column
          margin-bottom: 2rem
        .product-title
          font-size: 2rem
        .product-attributes-wrapper
          grid-template-columns: 100%
          gap: 1rem
      .product-cart
        flex-direction: column
        align-items: center
      .similar-products
        padding: 1rem 3rem 0rem
