.cake-constructor
    a
        color: #974558
        &:hover,
        &:focus
            text-decoration: none
    .close 
        svg
            @media #{$W575}
                max-width: 3rem

    .modal-dialog
        // max-width: 70vw
        max-width: 60rem
        width: 60rem
        @media #{$w1024}
            width: 100%
        @media #{$W575}
            width: auto

        .modal-header
            background-color: #FDF1F3
            flex-direction: row
            padding: 0.75rem 2rem
            background-image: url('../assets/img/bg-cake-constructor-modal.png')
            background-position: center center
            background-size: cover

            .cake-constuctor-title
                display: flex
                flex-direction: column
                justify-content: space-between
                width: 100%
                gap: 1rem

                .modal-title
                    color: #974558
                    font-size: 2.125rem
                    @media #{$W575}
                        line-height: 100%

            .cake-constructor-progress-wrapper
                width: 100%

                .cake-constructor-progress
                    list-style: none
                    display: inline-flex
                    gap: .5rem
                    padding: 0
                    margin: 0
                    color: #974558
                    @media #{$W575}
                        gap: .25rem
                        font-size: .75rem
                        line-height: 100%

                    .cake-constructor-progress-step
                        display: flex
                        justify-content: center
                        align-items: center
                        gap: .5rem

                        &::after
                            content: ''
                            display: inline-block
                            width: 1rem
                            height: 100%
                            position: relative
                            background-image: url("data:image/svg+xml, %3Csvg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16.407L1.65421 18L11 9L1.65421 0L0 1.593L7.69159 9L0 16.407Z' fill='%23DFB2BC'/%3E%3C/svg%3E")
                            background-repeat: no-repeat
                            background-size: 0.325rem
                            background-position: center

                        &:last-child
                            &::after
                                content: none

                    .active-step
                        color: #551920
                        font-weight: bold

                        a
                            color: #551920

        .modal-content
            padding: 0

            .modal-body
                padding: 0.75rem 2rem

            .cake-constructor-steps-wrapper
                padding: 1.5rem 1rem

            .cake-types-grid
                display: grid
                gap: 1rem
                grid-template-columns: 1fr 1fr 1fr
                @media #{$W575}
                    grid-template-columns: 100%

                .cake
                    display: flex
                    flex-direction: column
                    gap: 1.5rem
                    justify-content: start
                    text-align: center
                    width: 100%
                    transition: all ease-in-out .25s

                    .cake-preview-image
                        width: 100%
                        height: 17rem
                        display: flex
                        justify-content: center
                        align-items: center
                        border-radius: 1rem
                        border: 1px solid transparent
                        box-shadow: 0 .325rem .75rem #e3e3e378
                        transition: all ease-in-out .25s
                        @media #{$w992}
                            height: 10rem
                        @media #{$W575}
                            height: 15rem
                        img
                            width: 65%
                            transition: all ease-in-out .25s

                    .cake-title
                        font-family: $cursive
                        color: #551920
                        margin-bottom: 0
                    
                    .cake-description
                        color: #686868

                    &:hover
                        transform: scale(1.0250)

                        .cake-preview-image
                            border: 2px solid #FFD57E
                            img
                                width: 75%
                        
                        .cake-title
                            color: #9F6B00

            .cake-constructor-builder
                display: grid
                grid-template-columns: 1fr 1fr
                gap: 2.5rem
                @media #{$w992}
                    grid-template-columns: 100%

                .cake-constructor-visualization
                    background-color: #FCFCFC
                    border: 3px solid #F0F0F0
                    box-shadow: 0 0.5rem 1.5rem #97979721
                    padding: 1rem
                    border-radius: 1.75rem
                    margin-bottom: 1rem
                    @media #{$w992}
                        max-width: 75%
                        margin: 1rem auto
                    @media #{$W575}
                        max-width: 100%

                    .cake-constructor-header
                        display: flex
                        gap: 1rem
                        align-items: center
                        justify-content: space-between

                        .cake-heading
                            display: flex
                            flex-direction: column
                            text-align: center
                            gap: 0.125rem

                            .cake-title
                                color: #551920
                                font-family: $cursive
                                font-size: 1.725rem
                                margin-bottom: 0
                                line-height: 1
                                @media #{$w1024}
                                    font-size: 1.25rem
                                
                            .cake-total-weight
                                color: #686868
                                font-family: $cursive
                                font-size: 1rem
                                @media #{$W575}
                                    font-size: .75rem

                    .cake-constructor-render
                        display: flex
                        flex-direction: column-reverse
                        justify-content: center
                        align-items: center
                        width: 100%
                        min-height: 22.5rem
                        // border: 1px dashed blue
                        // background-color: #edf7ff
                        padding: 1rem
                        margin: 1rem 0
                        .floor-render
                            transition: all ease-in-out .25s
                            svg
                                max-width: 100%
                                height: fit-content
                                display: block
                                path,
                                ellipse
                                    transition: all ease-in-out .25s
                                    stroke-dasharray: 5
                                    stroke-width: 2px
                                    stroke: transparent
                            &:hover
                                svg
                                    path,
                                    ellipse
                                        stroke: #fd00ff

                            &.floor-render-round
                                &:nth-child(1)
                                    // background-color: red
                                    max-width: 90%
                                    margin-top: -3rem
                                    z-index: 0
                                    @media #{$W575}
                                        margin-top: -2rem
                                &:nth-child(2)
                                    // background-color: yellow
                                    max-width: 70%
                                    margin-top: -2rem
                                    z-index: 1
                                    @media #{$W575}
                                        margin-top: -1.5rem
                                &:nth-child(3)
                                    // background-color: blue
                                    max-width: 50%
                                    margin-top: -1rem
                                    z-index: 2
                                    @media #{$W575}
                                        margin-top: -0.75rem
                                &:nth-child(4)
                                    // background-color: green
                                    max-width: 30%
                                    margin-top: -0.5rem
                                    z-index: 3
                                    @media #{$W575}
                                        margin-top: -0.25rem
                                &:nth-child(5)
                                    // background-color: magenta
                                    max-width: 15%
                                    z-index: 4
                                    
                            &.floor-render-square
                                &:nth-child(1)
                                    // background-color: red
                                    max-width: 90%
                                    margin-top: -7.5rem
                                    z-index: 0
                                    @media #{$w1024}
                                        margin-top: -6rem
                                    @media #{$W575}
                                        margin-top: -4.25rem
                                &:nth-child(2)
                                    // background-color: yellow
                                    max-width: 70%
                                    margin-top: -5.325rem
                                    z-index: 1
                                    @media #{$w1024}
                                        margin-top: -4.325rem
                                    @media #{$W575}
                                        margin-top: -4.25rem
                                &:nth-child(3)
                                    // background-color: blue
                                    max-width: 50%
                                    margin-top: -3.25rem
                                    z-index: 2
                                    @media #{$W575}
                                        margin-top: -3.025rem
                                &:nth-child(4)
                                    // background-color: green
                                    max-width: 30%
                                    margin-top: -1.565rem
                                    z-index: 3
                                    @media #{$W575}
                                        margin-top: -2.025rem
                                &:nth-child(5)
                                    // background-color: magenta
                                    max-width: 15%
                                    z-index: 4
                                    @media #{$W575}
                                        margin-top: -0.8565rem

                            &.floor-render-custom
                                display: flex
                                flex-direction: column
                                align-items: center
                                gap: 0.5rem
                                a
                                    transition: all ease-in-out .25s
                                    svg
                                        path,
                                        ellipse
                                            stroke-dasharray: 0
                                            stroke-width: 1px
                                            stroke: #d5d5d5
                                    &:hover
                                        svg
                                            path,
                                            ellipse
                                                stroke-dasharray: 0
                                                stroke-width: 1px
                                                stroke: #07d900

                                .floors-wrapper
                                    display: flex
                                    flex-direction: row
                                    gap: .25rem

                                .attached-filepath
                                    .attached-file
                                        display: flex
                                        gap: .5rem
                                        // &:hover
                                        svg
                                            path,
                                            ellipse
                                                stroke-dasharray: 0
                                                stroke-width: 0
                                                stroke: #333333


                .cake-constructor-composition
                    .cake-composition-title
                        color: #686868
                        font-size: 1.25rem
                        font-family: $cursive

                    .cake-composition-description
                        color: #202020
                        font-weight: 200
                        font-size: 0.65rem
                        max-height: 3.5rem
                        overflow-y: scroll
                        @media #{$w1024}
                            max-height: unset

                .cake-constructor-settings
                    padding: 1rem

                    .cake-constructor-cake-wrapper
                        @media #{$W575}
                            text-align: center

                        .cake-constructor-cake-title
                            color: #974558
                            font-size: 2rem
                            font-family: $cursive

                        .cake-constructor-cake-description
                            color: #686868
                            font-size: 1rem
                            font-weight: 300

                    .floor-tastes-wrapper
                        display: flex
                        flex-direction: column-reverse

                    .floor-tastes
                        display: block
                        border-bottom: 1px solid #EAEAEA
                        padding: 0.5rem 0
                        padding-bottom: 2.5rem
                        margin-bottom: 2.5rem
                        transition: all ease-in-out .25s

                        &.floor-active
                            background-color: #fffff8
                            padding: 0.5rem 0
                            padding-bottom: 2.5rem
                            border-bottom-color: transparent

                        &:first-child
                            border-bottom: 0
                            padding-bottom: 0
                            margin-bottom: 2.5rem
                        
                        .floor-taste-title-wrapper
                            display: flex
                            justify-content: space-between
                            align-items: center
                            @media #{$W575}
                                flex-direction: column
                                margin-bottom: 1rem

                        .floor-taste-title
                            font-size: 1rem
                            font-weight: 700
                            margin-bottom: .5rem

                        .floor-taste-buttons
                            display: flex
                            flex-wrap: wrap
                            gap: .25rem

                            .btn
                                // flex: 1

                            .btn-taste
                                font-weight: 300
                                line-height: 1
                                color: #000
                                border-color: #000
                                font-size: .685rem
                                cursor: pointer
                                margin-bottom: 0
                                width: 32.50%
                                min-height: 3rem
                                display: flex
                                justify-content: center
                                align-items: center
                                white-space: normal
                                font-weight: 500
                                @media #{$w1024}
                                    width: 31.50%
                                @media #{$W575}
                                    width: 48.95%
                                &.active
                                    font-weight: bold
                
                    .cart-wrapper

                        .btn
                            width: 100%
                            background-color: #8B3A3A
                            color: #ffffff
                            font-size: 1rem
                            font-weight: 600
                            border-radius: .333rem
                            padding: 0.75rem
                            height: auto
                            border: 0
                            text-transform: none
                            display: flex
                            align-items: center
                            justify-content: center
                            gap: 1rem
                            transition: all ease-in-out .25s

                            &:hover
                                background-color: #974558
                
                .noUi-target 
                    background: #F0F0F0 !important
                    border-radius: 3rem !important
                    border: 0 !important
                    box-shadow: none !important

                .noUi-horizontal
                    height: 10px !important

                    .noUi-handle
                        width: 1rem !important
                        height: 1rem !important
                        border-radius: 10rem !important
                        background-color: #974558 !important
                        box-shadow: none !important
                        border: none !important
                        &::before,
                        &::after
                            content: none !important
                
                .noUi-tooltip
                    border: 0 !important
                    border-radius: 0.25rem !important
                    background: #000000cf !important
                    color: #fff !important
                    font-weight: 900 !important
                    padding: 0.25rem 0.5rem !important

.cake-mini-preview
    display: flex
    flex-direction: column-reverse
    align-items: center
    max-width: 100%

    svg
        max-width: 100%
        height: fit-content
        display: block
        path,
        ellipse
            transition: all ease-in-out .25s
            stroke-dasharray: 5
            stroke-width: 2px
            stroke: transparent
    &:hover
        svg
            path,
            ellipse
                stroke: #ffffff
                opacity: .95

    .floor-render-round
        position: relative
        &:nth-child(1)
            max-width: 90%
            top: -1rem
            z-index: 0
        &:nth-child(2)
            max-width: 70%
            top: 0rem
            z-index: 1
        &:nth-child(3)
            max-width: 50%
            top: 0.75rem
            z-index: 2
        &:nth-child(4)
            max-width: 30%
            top: 1.25rem
            z-index: 3
        &:nth-child(5)
            max-width: 15%
            top: 1.5rem
            z-index: 4

    .floor-render-square
        position: relative
        &:nth-child(1)
            max-width: 90%
            top: 0rem
            z-index: 0
        &:nth-child(2)
            max-width: 70%
            top: 2.757rem
            z-index: 1
        &:nth-child(3)
            max-width: 50%
            top: 4.75rem
            z-index: 2
        &:nth-child(4)
            max-width: 30%
            top: 6rem
            z-index: 3
        &:nth-child(5)
            max-width: 15%
            top: 6.65rem
            z-index: 4

    .floor-render-custom
        svg
            max-width: 4rem

.cake-tastes-list
    .badge
        border-color: #000000
        color: #000000

.cake-files 
    display: flex
    gap: 1rem
    padding: .5rem 0
    .cake-file
        display: flex
        align-items: center
        color: #647F94
        text-decoration: none
        svg
            max-height: 1rem
            max-width: 1rem