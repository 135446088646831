.catalog-title
  .show
    display: contents
    .border
      padding-right: 0.5rem
      border-right: 1px solid #974558
    .list-inline-item
      a
        color: #974558
  h1
    font: 2.5rem $cursive
    color: #511826
  @media #{$W700}
    text-align: center

.catalog-content
  font-size: 0.9rem
  color: #43373a
  li
    border-bottom: 1px solid #f2f2f2
  a[aria-expanded=true]
     .fa-chevron-right
       display: none
  a[aria-expanded=false]
    .fa-chevron-down
      display: none
  @media #{$W700}
    text-align: center
  li
    list-style-type: none
  a
    color: #974558
    text-decoration: none
    transition: color .25s
    &:hover
      color: #9f6b00
  .btn-secondary
    &:hover
      border-color: $color--dark-violet
  h1, h2, h3
    font: 2rem $cursive
    color: $color--chocolate-brown
  h2
    font: 1.5rem $cursive
    color: $color--dark-violet
  h3
    font: 1.33rem $cursive
    color: $color--dark-violet
    // min-height: 3rem
  .catalog-content-description
    min-height: 4rem
    .catalog-description-fixed-content-height
      display: block
      // height: 2.7rem
      // overflow: hidden
  .catalog-content-price
    // min-height: 3.65rem
  .catalog-content-allergens
    min-height: 2.00rem
  .form-control
    box-shadow: inset 0px 0px 30px 0px #dedede
    border: 0
    height: 2rem
    border-radius: 0
    font-size: .6rem
  .catalog-filter
    margin-top: 2rem
    .form-group
      margin-bottom: 2rem
    .title
      font: 1.75rem $cursive
      display: block
      margin-bottom: 1rem
      color: #511826
    label
      color: #000000
      display: block
    .form-control
      width: 100%
      max-width: 100% !important
    .filter-floors-count
      .floors-wrapper
        display: grid
        grid-template-columns: 1fr 1fr
        gap: .5rem
      .form-check
        display: inline-flex
        align-items: center
        label 
          font-size: .675rem
          color: #333333
      input[type="radio"]
        appearance: none
        background-color: #F0F0F0
        margin: 0
        font: inherit
        width: 1.15em
        height: 1.15em
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        &::before
          content: ""
          width: 0.65em
          height: 0.65em
          border-radius: 50%
          transform: scale(0)
          transition: 120ms transform ease-in-out
          background: #8D4A58
          display: block
        &:checked 
          &::before
            transform: scale(1)
        & + .form-control
          margin-top: 1em
    .filter-price
      #priceSlider
        margin-top: 2.5rem
        border-radius: 5rem
        background-color: #D9D9D9
        height: .25rem
        border: 0
        box-shadow: none
        .noUi-connects
          height: .25rem
        .noUi-connect
          background-color: #8D4A58
        .noUi-handle 
          width: 1rem
          height: 1rem
          top: -8px
          border: 0
          box-shadow: none
          border-radius: 35rem
          background-color: #8D4A58
          &::before, 
          &::after
            content: none
        .noUi-tooltip
          background-color: #BD8691
          color: #ffffff
          font-weight: bold
          padding: 1px 5px
          border: 0
    .filter-buttons
      display: grid
      grid-template-columns: 1fr auto
.catalog-content
  aside
    article
      &:first-child
        #accordion
          //border-bottom: 1px dashed #ccc
        ul
          padding-left: 1.5rem
          @media #{$W700}
            padding-left: 0
        li
          list-style-image: url(/../assets/img/list-style-image.png)
          &:hover
            list-style-image: url(/../assets/img/list-style-image-hover.png)
          @media #{$W700}
            list-style-image: none
            &:hover
              list-style-image: none
        .depth:not(:first-child)
          // border-bottom: 1px dashed #ccc
        .depth:last-child
          border: 0
        .depth-1
          font-size: 1.25rem
          font-family: $cursive
          font-style: normal
          a
            color: $color--chocolate-brown
        .depth-2
          font-size: 1rem
          font-style: normal
          a
            color: $color--dark-violet
        .depth-3
          font-size: 0.95rem
          font-style: normal
          a
            color: $color--medium-pink
      &:last-child
        @media #{$W700}
          margin-bottom: 1rem
        .form-control
          max-width: 90%
          @media #{$W700}
            margin: 0 auto
            text-align: center

.catalog-content
  .conditery,
  .services
    +flex-parent(100%, row wrap, flex-start, flex-start)
    // font-size: 0.6rem
    i
      font-size: 1rem
    span
      width: 100%
    article
      +flex-parent(100%, column nowrap, flex-start, center)
      background-color: #fff
      border-radius: 2rem
      transition: $transition-25
      text-align: center
      &:hover
        background-color: #f7f7f7
      @media #{$W768}
        +flex-parent(100%, column nowrap, flex-start, center)
      @media #{$W700}
        +flex-parent(100%, column nowrap, flex-start, center)
        margin-right: 0 !important
      img
        height: 15rem
        width: 100%
        object-fit: cover
        border-radius: 1.25rem
      .product-title
        a
          font-family: $cursive
          display: flex
          justify-content: center
          text-align: center
          gap: .25rem
        svg
          max-width: 1.5rem

.catalog-content
  .culinary
    +flex-parent(100%, row wrap, flex-start, flex-start)
    font-size: 0.6rem
    article
      +flex-parent(100%, row nowrap, flex-start, center)
      background-color: #fff
      border-radius: 2rem
      transition: $transition-25
      text-align: center
      &:hover
        background-color: #f7f7f7
      @media #{$W768}
        +flex-parent(100%, column nowrap, flex-start, center)
      .product-title
        font-family: $cursive
        justify-content: center
        text-align: center
        
    .thumb, .details, .price, .quantity
      +flex-parent(auto, column nowrap, flex-start, flex-start)
    .thumb
      width: 100%
      @media #{$W768}
        margin-right: 0 !important
      img
        max-width: 100%
        border-radius: .25rem
    .details
      width: 40%
      @media #{$W768}
        text-align: center
        h3
          margin-top: .5rem !important
    .price
      width: 20%
      @media #{$W768}
        margin: .5rem 0
    .quantity
      +flex-parent(20%, row nowrap, flex-start, center)
      .form-control
        width: 50%
      @media #{$W768}
        +flex-parent(100%, row nowrap, center, center)
    .thumb, .details, .price
      @media #{$W768}
        +flex-parent(100%, column nowrap, flex-start, center)

.catalog-content
  .services
    img
      height: 30%

.pagination
  display: flex
  justify-content: start !important
  text-align: center
  gap: 1rem
  li
    border-bottom: none
  .page-item
    .page-link
      //border-radius: 50%
      padding: inherit
      font-size: 0.85rem
      height: 30px
      width: 30px
      line-height: 30px
      border-radius: 1rem
      transition: $transition-25
      border: none
    &:hover
      .page-link
        background-color: #974558
        color: #fff
    &.active
      .page-link
        background-color: #974558
        color: #fff

[id*="_wrapper"]
  background-color: #fff
  max-height: 100%
  height: auto !important

#standalone
  transform: scale(0.8)

.popup_visible #standalone
  transform: scale(1)

[id*="makeAcake"]
  .btn-secondary
    border-radius: .25rem
    border: 1px solid #974558
    color: #974558
    font-size: 0.6rem
    background: #fff
    &:hover
      background-color: #974558
      color: #fff
      cursor: pointer
.cakesWrapper
  width: 100%
  margin: 3rem 0
  text-align: center
#cakeWeight1,#cakeWeight2,#cakeWeight3,#cakeWeight4,#cakeWeight5
  width: 50%
  margin: 0 auto
#makeAcakeStep1
  display: flex !important
  flex-flow: row wrap !important
  justify-content: center !important
  align-items: stretch !important
  font-size: .7rem
  font-style: italic
  img
    @media #{$W768}
      max-width: 50% !important
  h3, h4
    font: 2rem $cursive
    color: #974558
  h4
    font-size: .9rem
  span
    width: 100%
  [class*="cakeType-"]
    +flex-parent(33.3%, column nowrap, space-between, center)
    @media #{$W768}
      +flex-parent(50%, column nowrap, space-between, center)
      margin-top: .25rem !important
      margin-bottom: .25rem !important
    @media #{$W575}
      +flex-parent(100%, column nowrap, space-between, center)
    border-radius: .25rem
    transition: .125s
    &:hover
      background: #f7f7f7
  .form-control
    margin: 0 auto
    text-align: center
    width: auto
    box-shadow: inset 0px 0px 30px 0px #dedede
    border: 0
    height: 2rem
    font-size: .6rem
  .form-control-file
    @extend .form-control
    box-shadow: none

#makeAcakeStep2_cakeType-1
  display: flex !important
  flex-flow: column nowrap !important
  justify-content: flex-start !important
  align-items: center !important
  font-size: .7rem
  font-style: italic
  background-color: #fff
  width: 100%
  span, .details
    width: 100%
  .details
    +flex-parent(100%, row wrap, flex-start, flex-start)
    @media #{$W700}
      +flex-parent(100%, column nowrap, flex-start, flex-start)
  .tastes
    +flex-parent(50%, column wrap, flex-start, flex-start)
    max-height: 80vh
    @media #{$W700}
      +flex-parent(100%, column nowrap, flex-start, center)
      max-height: auto
  .floors
    +flex-parent(50%, column wrap, flex-start, center)
    @media #{$W700}
      +flex-parent(100%, column nowrap, flex-start, center)
    div
      +flex-parent(50%, row nowrap, flex-start, flex-end)
      padding: .25rem
      color: #fff
      height: 5rem
      background-color: #dedede
      background-image: url("../assets/img/cake-deco.png")
      background-repeat: no-repeat
      background-size: contain
      background-position: center center
      border-radius: 1rem 1rem 0 0
      @media #{$W700}
        width: 100% !important
        flex-flow: column nowrap !important
        align-items: center !important
  .additional-tastes
    +flex-parent(50%, column wrap, flex-start, flex-start)
  .more-tastes
    color: #974558
    text-decoration: none
    border-bottom: 1px dashed #974558
    font-size: 1rem
    display: block
    margin-bottom: 10px
    transition: color 0.25s
  .floor-buttons
    +flex-parent(50%, column wrap, flex-start, flex-start)
  .btn-add-floor
    margin-top: 15px
    margin-bottom: 15px
  .tastes
    div
      +flex-parent(14rem, column nowrap, center, center)
      border-radius: .2rem
      font-size: 0.7rem
      height: 2rem
    .chocolate
      background-color: #ac6045
      color: #fff
    .caramel
      background-color: #f4cdbe
      color: #963715
    .banana
      background-color: #f6de80
      color: #795548
    .lime
      background-color: #aae079
      color: #265100
    .blueberry
      background-color: #797bc9
      color: #fff
    .rapsberry
      background-color: #904a9a
      color: #fff
    .strawberry
      background-color: #d04d4d
      color: #fff

#makeAcakeStep2_cakeType-2
  @extend #makeAcakeStep2_cakeType-1
  .floors
    div
      +flex-parent(50%, row nowrap, flex-start, flex-end)
      padding: .25rem
      color: #fff
      height: 5rem
      background-color: #dedede
      div
        width: 50%
        height: 100%
        background-image: none
        background-color: transparent
        span
          width: auto
          opacity: .7
      div:first-of-type
        border-top-left-radius: 15px
        border-top-right-radius: 0
      div:last-of-type
        border-top-left-radius: 0
        border-top-right-radius: 15px

#makeAcakeStep2_cakeType-3
  @extend #makeAcakeStep2_cakeType-1
  @extend #makeAcakeStep2_cakeType-2
  .floors
    div
      @media #{$W700}
        width: 100% !important
        flex-flow: column nowrap !important
        justify-content: center !important
        align-items: center !important
        height: 10rem !important
      div
        width: 33%
        border-radius: 0
      div:first-of-type
        border-top-left-radius: 15px
        border-top-right-radius: 0
      div:last-of-type
        border-top-left-radius: 0
        border-top-right-radius: 15px

#makeAcakeStep2_cakeType-4
  @extend #makeAcakeStep2_cakeType-1
  @extend #makeAcakeStep2_cakeType-2
  .floors
    div > div
      width: 100%
      border-top-left-radius: 15px !important
      border-top-right-radius: 15px !important

#makeAcakeStep2_cakeType-5
  @extend #makeAcakeStep2_cakeType-1
  @extend #makeAcakeStep2_cakeType-2
  .floors
    +flex-parent(50%, column wrap, flex-start, center)
    div > div
      width: 100%
      border-top-left-radius: 15px !important
      border-top-right-radius: 15px !important
    @media #{$W700}
      +flex-parent(100%, column nowrap, flex-start, center)
    .floor
      &:first-of-type
        +flex-parent(30%, row nowrap, flex-start, flex-end)
        @media #{$W700}
          width: 50% !important
      &:nth-of-type(2)
        +flex-parent(40%, row nowrap, flex-start, flex-end)
        @media #{$W700}
          width: 75% !important
      &:nth-of-type(3)
        +flex-parent(50%, row nowrap, flex-start, flex-end)

.conditery
  article:hover
    cursor: pointer

.gslide
  a:not([href]):not([tabindex])
   color: #974558
.gslide-title
    color: #974558 !important

.bulk-items-wrapper
  .bulk-items
    .bulk-item-divider
      font: 1.25rem $cursive
      color: #974558
      margin-top: 1rem
      margin-bottom: 0.5rem
      display: block
      border-bottom: 1px solid #a57983
    .bulk-item
      border-bottom: 1px solid #eaeaea
      padding: 0.25rem
      .bulk-item-name
        display: flex
        align-items: center
        font-size: 1rem
        flex-direction: column
        max-width: 75%
        .bulk-item-description
          font-size: .75rem
          color: #666666
          p
            margin-bottom: 0
        .bulk-item-taste
          border: 1px solid #974558
          color: #974558
          padding: 0.125rem
          font-size: .65rem
          text-align: center
          border-radius: 0.25rem
        .fa, .fas, .fab
          font-size: .625rem
      .bulk-item-count
        display: flex
        align-items: center
        justify-content: center
        width: 4rem
        color: #974558
        font-weight: bold
        border-radius: 0.25rem

.category-highlight
  padding: 0.25rem 0.5rem
  border-radius: 0.5rem
  .depth-2
    font-size: 1.00rem !important
    font-weight: bold
  .depth-3
    font-size: 0.90rem !important
    font-weight: bold
  .depth-4
    font-size: 0.80rem !important
    font-weight: bold

.btn-is-quickly-made
  margin: .25rem 0 .75rem 0
  border-radius: 35rem
  background-color: #FDBB65
  padding: 0.25rem 1rem
  color: white !important
  font-weight: bold
  transition: all .5s ease
  &:hover
    transform: scale(1.125)
    background-color: #511826

.modal-quick-cakes
  font-family: $cursive
  color: #974558
