
body
  background-color: $color--light-pink
  font-family: $sans-serif

.wrapper
  background-color: #fff

.modal
  overflow: auto !important
  font-size: .75rem
  p,
  ul,
  ol,
  dl,
  span
  div
    font-size: .75rem

.pagination
  +flex-parent(100%, row wrap, space-between, center)
  @media #{$W768}
    +flex-parent(100%, column nowrap, flex-start, center)
    div
      margin-bottom: .5rem
  ul
    display: inline

.fixed-icons-nav
  top: 50%
  transform: translateY(-50%)
  position: fixed
  height: 5rem
  weight: 5rem
  [class*="fixed-icons-nav-"]
    margin-bottom: .5rem
    padding: .5rem
    background-color: #fff
    border-radius: 0 .5rem .5rem 0
  img
    max-width: 1.5rem

.section
  padding: 3rem 3rem
  .section-title
    font-size: 5rem
    @media #{$w1440}
      font-size: 3.75rem
    @media #{$w1366}
      font-size: 3.5rem
    @media #{$W575}
      font-size: 2.75rem
    line-height: 75%
    color: $color--chocolate-brown
    font-weight: 900
    font-family: $cursive
    margin-bottom: 0
  a
    color: $color--caramel-brown
    font-family: $cursive
    text-decoration: none    
    display: flex
    align-items: center
    gap: 1rem
    svg
      margin-left: 0
      transition: $transition-25
    &:hover
      svg
        margin-left: 1rem

.section-wide
  padding: 6rem 12rem
  @media #{$w1366}
    padding: 6rem 6rem
  @media #{$W575}
    padding: 3rem 2rem

.btn-primary
  color: $color--chocolate-brown
  font-family: $cursive
  background-color: transparent
  border-color: #b0a3a1
  border-width: 2px
  font-size: 1.25rem
  padding: 0.75rem 1.25rem
  border-radius: 2rem
  display: inline-flex
  gap: .5rem
  align-items: center
  justify-content: center
  cursor: pointer
  &:hover
    background-color: $color--chocolate-brown
    color: $color--white !important
    border-color: $color--chocolate-brown
  svg
    max-width: 1.5rem

.btn-secondary
  color: $color--dark-violet
  font-family: $cursive
  background-color: transparent
  border-color: #c3a6ad
  border-width: 1px
  padding: 0.5rem 1.5rem
  border-radius: 2rem
  display: inline-flex
  gap: .5rem
  align-items: center
  cursor: pointer
  &:hover
    background-color: $color--dark-violet
    color: $color--white !important
    border-color: $color--dark-violet
  svg
    max-width: 1.5rem

.btn-danger
  color: $color--strawberry-red
  font-family: $cursive
  background-color: transparent
  border-color: #fa9ca7
  border-width: 2px
  font-size: 1.25rem
  padding: 0.75rem 1.25rem
  border-radius: 2rem
  display: inline-flex
  gap: .5rem
  align-items: center
  justify-content: center
  cursor: pointer
  &:hover
    background-color: $color--strawberry-red
    color: $color--white !important
    border-color: $color--strawberry-red
  svg
    max-width: 1.5rem

#toast-container > div
  border-radius: 1rem
  box-shadow: none

#toast-container > div:hover
  box-shadow: none

.toast-info
  background-color: #0052c5

.toast-error
  background-color: #d30000

.toast-success
  background-color: #00ba19

.toast-warning
  background-color: #ff9500

.modal-content 
  border-radius: 2rem !important
  .modal-header
    border-top-left-radius: 2rem
    border-top-right-radius: 2rem

.alert
  border-radius: 1rem

.badge
  border-radius: 3rem
  padding: 0.25rem 0.75rem
  font-size: .75rem
  font-weight: 500