.page-auth
    background-image: url('../assets/img/bg-page-auth.jpg')
    background-size: cover
    background-position: center center
    background-repeat: no-repeat
    .form
        min-height: 80vh
        a
            color: #8D4A58
        .form-action-link
            font-size: .85rem

        .card
            border: 0
            border-radius: 0
            min-width: 50%
            padding: 2rem 4rem 1rem
            border-radius: 2rem
            @media #{$W575}
                padding: 1rem 2rem 1rem
            .form-header
                text-align: center
                margin-bottom: 2rem
                .form-title
                    color: #4A1C26
                    font-family: $cursive
        
        .col-form-label
            font-size: .75rem

        .form-control
            box-shadow: inset 0px 0px 30px 0px #dedede
            border: 0
            border-radius: 0
            font-size: .75rem
            padding: 0.75rem 1rem

.page-restore-password
    .form-restore-pass-reset
        .card
            max-width: 65%
            padding: 3rem 5rem 2rem
            @media #{$W575}
                max-width: 100%
                padding: 2rem 1rem 2rem
            .form-wrapper
                padding: 0rem 4rem
                @media #{$W575}
                    padding: 0rem 1rem
                .btn 
                    width: 100%

// TODO    
.page-profile
    background-image: url('../assets/img/bg-page-auth.jpg')
    background-size: cover
    background-position: center center
    background-repeat: no-repeat
    .profile
        min-height: 80vh
        width: 80vw
        margin-left: auto
        @media #{$W575}
            min-height: 100%
            width: 100%
            margin-left: 0
        a
            color: #8D4A58
        h1, h2, h3
            font-family: $cursive
        h2
            color: #974558
            font-size: 1.25rem
        h3
            font: .9rem $sans-serif
            color: #974558
            font-style: italic

        .form-action-link
            font-size: .85rem

        .card
            border: 0
            border-radius: 0
            min-width: 80vw
            padding: 2rem 4rem 1rem
            @media #{$W575}
                min-width: 100%
                padding: 2rem 2rem 1rem
            .form-header
                margin-bottom: 1rem
                .form-title
                    color: #4A1C26
                    font-family: $cursive
        
        .col-form-label
            font-size: .75rem

        .form-control
            box-shadow: inset 0px 0px 30px 0px #dedede
            border: 0
            border-radius: 0
            font-size: .75rem
            padding: 0.75rem 1rem

        .nav-pills
            margin-bottom: 1rem
            gap: .5rem
            @media #{$W575}
                flex-direction: column
            .nav-link
                color: #974558
                background-color: transparent
                border-radius: 0
                transition: all .25s ease-in-out
                border-radius: 35rem
                &.active,
                &:hover
                    background-color: #974558 !important
                    color: #fff
                @media #{$W575}
                    width: 100%
                    text-align: center
                    font-size: .75rem
                    border-width: 2px
            .nav-item-danger
                margin-left: auto
                @media #{$W575}
                    margin-left: 0

            .nav-link-danger
                border-color: #e91e63
                color: #e91e63
                &:hover
                    background-color: #e91e63 !important
                    color: #ffffff

        .orders-info-text 
            color: #A0A0A0
            width: 50%
            font-size: .75rem
            @media #{$W575}
                width: 100%
            
        .badge-order-status
            font-weight: 400
            width: 75%
            padding: 0.75rem 1rem
            font-size: .75rem
            background-color: #616161
            color: #000000
            @media #{$W575}
                width: 100%
            &.badge-order-status-new
                background-color: #C9F2F8
                color: #002071
            &.badge-order-status-confirmed
                background-color: #CAF8C9
                color: #007120
            &.badge-order-status-cancelled
                background-color: #F8C9C9
                color: #710000
        .table
            font-size: .75rem
            &.table-md
                th, td
                    padding: 0.5rem 0.75rem
                    vertical-align: top
            thead
                background-color: #FAFAFA
                color: #616161
            th
                border-top: 0
                border-bottom: 0
                font-weight: 400
    
    .profile-order-preview
        .card 
            .form-header
                margin-bottom: 2rem