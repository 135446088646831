.blog
  +flex-parent(100%, row wrap, flex-start, flex-start)
  font-size: 0.6rem
  span
    width: 100%
  article
    +flex-parent(100%, row wrap, flex-start, flex-start)
    background-color: #fff
    border-radius: .25rem
    p
      text-align: left
    @media #{$W768}
      +flex-parent(100%, column nowrap, flex-start, flex-start)
    &:hover
      background-color: #f7f7f7
    div
      width: 50%
      @media #{$W768}
        width: 100%
      @media #{$W575}
        padding-left: 0 !important
      div
        +flex-parent(100%, row wrap, flex-start, center)
        span
          width: 50%
          &:last-of-type
            text-align: right
            @media #{$W575}
              text-align: left
              margin-top: 1rem
          @media #{$W575}
            width: 100%
            text-align: left

    h3
      font: 1.3rem $cursive
      color: #9f6b00
      @media #{$W768}
        margin-top: 1rem
    p
      font-size: .7rem
    img
      max-width: 100%
      border-radius: .25rem

.blog-single
  font-size: 0.7rem
  color: #43373a
  .title
    +flex-parent(100%, row wrap, space-between, center)
    @media #{$W768}
      margin-bottom: 1rem
    @media #{$W400}
      +flex-parent(100%, row wrap, center, center)
      span
        width: 50%
      span:last-of-type
        text-align: right
  a
    color: #9f6b00
    &:hover
      color: #ffbe5e
      text-decoration: none
  h1,
  h2,
  h3
    font: 2.5rem $cursive
    color: #511826
    @media #{$W400}
      text-align: center
  h2
    font-size: 1.25rem
  h3
    font-size: 1rem
    color: #974558
  .btn-secondary
    border-radius: 0rem
    border: 2px solid #974558
    color: #974558
    font-size: 0.6rem
    background: #fff
    &:hover
      background-color: #974558
      color: #fff
      cursor: pointer
    @media #{$W400}
      margin-top: 1rem
