/* Variables */

$W320: "only screen and (max-width: 320px)"
$W575: "only screen and (max-width: 575px)"

$W700: "only screen and (max-width: 700px)"
$W768: "only screen and (max-width: 768px)"
$W823: "only screen and (max-width: 823px)"

$w1440: "only screen and (max-width: 1440px)"
$w1366: "only screen and (max-width: 1366px)"
$w1024: "only screen and (max-width: 1024px)"
$w992: "only screen and (max-width: 992px)"
$w768: "only screen and (max-width: 768px)"
$w360: "only screen and (max-width: 375px)"
$h570: "only screen and (max-height: 570px)"
$h480: "only screen and (max-height: 480px)"
$W400: "only screen and (max-width: 400px)"
$h320: "only screen and (max-height: 320px)"

// Fonts
$cursive: 'Noto Serif Display', serif
$sans-serif: 'Inter', sans-serif

// Colors V2
$color--chocolate-brown: #462D2A
$color--light-pink: #FDF9FB
$color--caramel-brown: #CC4E06
$color--gray: #686868
$color--medium-pink: #732839
$color--white: #FFF
$color--cake-pink: #E3B2CC
$color--light-gray: #E8E7EB
$color--dark-violet: #551920
$color--strawberry-red: #F63349

// Transitions
$transition-25: all .25s ease-out

// Mixings
=flex-parent($width, $flow, $justify, $align)
  display: flex
  width: $width
  flex-flow: $flow
  justify-content: $justify
  align-items: $align
