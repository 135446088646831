.footer,
.footer-v2
  background-color: $color--medium-pink
  padding: 3rem 3rem
  .footer-logo
    width: 16.5rem

  *
    color: $color--light-pink
    @media #{$W575}
      gap: 1rem

  a
    color: $color--cake-pink
    text-decoration: none
    transition: $transition-25
    &:hover
      color: $color--white

  .container-fluid,
  .container
    display: grid
    gap: 2rem
  
  .footer-title
    font-family: $cursive    
    font-size: 1.75rem
    margin-bottom: 1.5rem
    @media #{$w1024}
      font-size: 1.25rem

  .form-control
    border: 1px solid $color--cake-pink
    background-color: transparent
    padding: 0.25rem 1.75rem
    border-radius: .5rem
    color: $color--light-pink
    transition: $transition-25
    &::placeholder
      opacity: .5
      color: $color--cake-pink
    &:focus
      background-color: $color--medium-pink
      transform: scale(1.125)
  
  .btn
    border-radius: .5rem

  .btn-pink
    background-color: $color--dark-violet
    border-color: $color--dark-violet
    color: $color--white
    transition: $transition-25
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    &:hover
      background-color: $color--strawberry-red
      border-color: $color--strawberry-red

  .footer-nav
    margin-bottom: 0
    padding-left: 0.75rem
    list-style-type: '- '
  .footer-col-subscribe
    @media #{$W575}
      flex-direction: column
  .newsletter-form
    display: flex
    gap: .25rem

  .row-1st
    .footer-col
      display: flex
      align-items: center
    .footer-title
      margin-bottom: 0

  .footer-socials
    a
      color: $color--white
      transition: $transition-25
      &:hover
        color: $color--caramel-brown