.offcanvas-modal.left .modal-dialog,
.offcanvas-modal.right .modal-dialog
    position: fixed
    margin: auto
    width: 320px
    @media #{$W575}
        width: 100%
    height: 100%    
    top: 0
    -webkit-transform: translate3d(0%, 0, 0)
        -ms-transform: translate3d(0%, 0, 0)
            -o-transform: translate3d(0%, 0, 0)
            transform: translate3d(0%, 0, 0)

.offcanvas-modal.left .modal-content,
.offcanvas-modal.right .modal-content
    height: 100%
    overflow-y: auto

/*Left*/
.offcanvas-modal.left.fade .modal-dialog
    left: -320px
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out
            -o-transition: opacity 0.3s linear, left 0.3s ease-out
            transition: opacity 0.3s linear, left 0.3s ease-out

.offcanvas-modal.left.fade.show .modal-dialog
    left: 0
    
/*Right*/
.offcanvas-modal.right.fade .modal-dialog 
    right: -320px
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out
            -o-transition: opacity 0.3s linear, right 0.3s ease-out
            transition: opacity 0.3s linear, right 0.3s ease-out

.offcanvas-modal.right.fade.show .modal-dialog 
    right: 0


/* ----- MODAL STYLE ----- */
.offcanvas-modal
    border-radius: 0
    border: none
    .close
        svg
            max-width: 2rem
            max-height: 2rem
    .modal-header,
    .modal-body
        padding: 0

.modal-dialog-slideout .modal-content
    border: 0
    padding: 2.5rem 2.5rem
    gap: 2rem
    border-radius: 0 !important

.offcanvas-modal .modal-content .modal-header 
    border-bottom-color: #EEEEEE
    justify-content: space-between

.modal-dialog-slideout
    min-height: 100%
    margin: 0 auto 0 0
    @media #{$W575}
        width: 80%

.modal.fade .modal-dialog.modal-dialog-slideout
    -webkit-transform: translate(-100%, 0)
    transform: translate(-100%, 0)

.modal.fade.show .modal-dialog.modal-dialog-slideout
    -webkit-transform: translate(0, 0)
    transform: translate(0, 0)
    flex-flow: column

.modal-dialog-slideout .modal-content
    border: 0


.navbar-offcanvas-pages
    display: flex
    flex-direction: column
    gap: 2rem
    .offcanvas-socials
        display: flex
        gap: 1rem
        a
            font-size: 2rem
            color: $color--dark-violet
            transition: $transition-25
            &:hover
                color: $color--caramel-brown

    .navbar-nav
        display: flex
        flex-direction: column
        gap: 1rem
        .nav-item
            .nav-link
                font-family: $cursive
                font-size: 1.25rem
                border-bottom: 1px solid $color--light-gray
                color: $color--chocolate-brown
                transition: $transition-25
                &:hover
                    color: $color--caramel-brown
                    padding-left: 1rem