.about-content
  font-size: 0.7rem
  color: #43373a
  aside
    p:nth-child(even)
      color: #974558
  @media #{$W768}
    text-align: center
  a
    color: #9f6b00
    &:hover
      color: #ffbe5e
      text-decoration: none
  h1,
  h2,
  h3
    font: 2.5rem $cursive
    color: #511826
  h2
    font-size: 1.25rem
  h3
    font-size: 1rem
    color: #974558
  article
    //border-right: 1px dashed #ccc
    @media #{$W768}
      padding: 0 !important
      border: 0

.about-gallery
  .owl-item
    height: 25rem
    figure
      &:before
        content: ""
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background: rgba(58,26,20,.6)
        transition: all .5s ease
      &:hover:before
        background-color: rgba(255,255,255,.25)
        cursor: pointer
        z-index: -1
        a
          z-index: 100000
      a
        // position: absolute
        z-index: 100000
        img
          display: block
          width: auto
          height: 100%
  .owl-nav
    font: 1.5rem
    color: #fff
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    width: 100%
    opacity: .5
    &:hover
      opacity: 1
    .owl-prev
      position: absolute
      left: 12.25rem
      &:before
        //content: "\f053"
        content: '\f053'
        font-family: 'Font Awesome 5 Free'
        font-weight: 900
        font-size: 3rem
    .owl-next
      position: absolute
      right: 12.25rem
      &:before
        content: "\f054"
        font-family: 'Font Awesome 5 Free'
        font-weight: 900
        font-size: 3rem
.about-reviews
  font-size: 0.7rem
  @media #{$W768}
    text-align: center
  .owl-nav
    font: 1.5rem FontAwesome
    color: #000
    position: absolute
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    width: 20%
    opacity: .5
    &:hover
      opacity: 1
    .owl-prev
      position: absolute
      left: 0
      &:before
        content: "\f053"
        font-family: "Font Awesome 5 Free"
        font-weight: 900
        font-size: 1rem
    .owl-next
      position: absolute
      right: 0
      &:before
        content: "\f054"
        font-family: "Font Awesome 5 Free"
        font-weight: 900
        font-size: 1rem

.reviews
  margin-bottom: 5rem
  h2
    font: 2.5rem $cursive
    color: #511826
    font-size: 1.25rem
    @media #{$W768}
      text-align: center
