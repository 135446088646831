.page-index
  .section-hero
      display: flex
      align-items: center
      padding: 1rem 3rem
      min-height: 50rem
      @media #{$w1440}
        min-height: 42.50rem
      @media #{$w1366}
        min-height: 35rem
      background-size: cover
      background-position: center center
      .hero-content-wrapper
        display: flex
        flex-direction: column
        gap: 2rem
        .hero-title
          font-size: 8rem
          @media #{$w1440}
            font-size: 6rem
          @media #{$w1366}
            font-size: 5rem
          @media #{$W575}
            font-size: 3.5rem
          line-height: 75%
          color: $color--white
          font-weight: 900
          font-family: $cursive
          text-shadow: 0 0 2rem #00000025
          margin-bottom: 0
        .hero-cta-button
          color: $color--white
          font-family: $cursive
          font-size: 2rem
          text-decoration: none
          text-shadow: 0 0 2rem #00000050
          svg
            margin-left: 0
            transition: $transition-25
          &:hover
            svg
              margin-left: 1rem

  .section-intro
    .section-content-wrapper
      display: grid
      gap: 2rem
      grid-template-columns: 1fr 2fr
      @media #{$w768}
        grid-template-columns: 100%
      .section-description
        font-size: 2rem
        @media #{$w1440}
          font-size: 1.75rem
        @media #{$w1366}
          font-size: 1.5rem
        @media #{$W575}
          font-size: 1.25rem
        margin-top: 5rem
        color: $color--chocolate-brown
        font-family: $cursive
  
  .section-categories
    background-color: $color--white
    margin: 4rem 6rem
    @media #{$w768}
      margin: 0rem 2rem
    @media #{$W575}
      margin: 0rem 1rem
    box-shadow: 0 2rem 2rem #00000010
    border-radius: 3.5rem
    .categories-content-wrapper
      display: grid
      gap: 2rem
      grid-template-columns: 1fr 1fr 1fr
      @media #{$W575}
        grid-template-columns: 100%
      .category
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        border-right: .5rem solid $color--light-pink
        @media #{$w768}
          border-right: 0
        &:last-child
          border-right: none
        .category-image
          width: 75%
        .category-title
          font-size: 1.75rem
          @media #{$w768}
            font-size: 1.25rem
          @media #{$W575}
            font-size: 1.0125rem
          text-align: center
          color: $color--dark-violet

  .section-favorite
    .section-content-wrapper
      display: flex
      align-items: center
      flex-direction: column
      gap: 5rem

    .product-content-wrapper
      display: flex
      justify-content: center
      text-align: center
      gap: 2rem
      @media #{$w768}
        flex-direction: column
        align-items: center
      .col-12
        article
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column
          // min-width: 35rem
          a
            align-items: start
            justify-content: center
          .gallery-item
            justify-content: center
          .catalog-content-description

  .section-discounts
    background-color: $color--white
    margin: 4rem 6rem
    box-shadow: 0 2rem 2rem #00000010
    border-radius: 3.5rem
    @media #{$W575}
      margin: 2rem 0rem
      border-radius: 0
    .section-content-wrapper
      display: grid
      align-items: center
      grid-template-columns: 1fr 1fr
      gap: 20rem
      @media #{$w1366}
        grid-template-columns: 1fr
        gap: 10rem
      @media #{$w1024}
        display: flex
        flex-direction: column-reverse
        justify-content: center
        text-align: center
        gap: 5rem
    .section-text-wrapper
        display: flex
        flex-direction: column
        gap: 3rem
        .section-title
          color: $color--dark-violet
        .section-description
          color: $color--gray
          font-size: 1.25rem
    .product-content-wrapper
      display: flex
      justify-content: center
      text-align: center
      .col-12
        article
          width: 30rem
          @media #{$w1440}
            width: 20rem
          @media #{$w1366}
            width: 15rem
          @media #{$w1024}
            width: 100%
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column
          a
            align-items: start
            justify-content: center
          .gallery-item
            justify-content: center
          .catalog-content-description

  .section-presentation
    .section-image-wrapper
      display: flex
      justify-content: end
      @media #{$w768}
        justify-content: center
      img
        box-shadow: 0 2rem 2rem #00000017
        border-radius: 3rem
    .section-content-wrapper
      display: grid
      justify-content: space-between
      align-items: center
      gap: 5rem
      grid-template-columns: 1fr 1fr
      @media #{$w768}
        grid-template-columns: 100%
      .section-text-wrapper
        display: flex
        flex-direction: column
        gap: 3rem
      .section-title
      .section-description
        margin-left: 6rem
        color: $color--gray
        font-size: 1.25rem
        @media #{$w1024}
          margin-left: 2rem
      .section-cta-button
        font-size: 1.5rem

  .trustpilot
    margin: 2rem 0
    display: flex
    align-items: center
    svg, img
      width: 10rem

  .section-branches
    .branch-content-wrapper
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      @media #{$w768}
        grid-template-columns: 100%
        text-align: center
      .branch
        display: flex
        flex-direction: column
        gap: 1rem
        border-right: 1px solid $color--light-gray
        padding: 2rem 5rem
        @media #{$w1024}
          padding: 2rem 2rem
        @media #{$w768}
          border-right: none
        &:first-child
          padding-left: 0rem
          @media #{$w768}
            padding-left: 2rem
        &:last-child
          border-right: none
          padding-right: 0rem
          @media #{$w768}
            padding-right: 2rem
        .title
          font-size: .85rem
          color: $color--gray
        .addess
          font-size: 1.25rem
          color: $color--caramel-brown
          font-family: $cursive
        .hours
          font-size: .85rem
          color: $color--chocolate-brown