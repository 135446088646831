.modal-open
  padding-right: 0 !important
  overflow-Y: scroll

.wrapper
  background-color: #fff

.contacts-content
  font-size: 0.7rem
  color: #43373a
  @media #{$W768}
    text-align: center
  a
    color: #9f6b00
    &:hover
      color: #ffbe5e
      text-decoration: none
  h1,
  h2,
  h3
    font: 2.5rem $cursive
    color: #511826
  h2
    font-size: 1.25rem
  h3
    font-size: 1rem
    color: #974558
  article
    //border-right: 1px dashed #ccc
    min-height: 14rem
    [class*="col-"]
      background: #f7f7f7
    @media #{$W768}
      padding: 0 !important
      border: 0
  aside
    p
      &:nth-child(even)
        //border-bottom: 1px dashed #ccc
        color: #974558
      &:last-of-type
        border: 0
    .btn-lg
      border-radius: 0
      border: 2px solid #974558
      color: #974558
      font-size: 0.7rem
      background: #fff
      width: 100%
      &:hover
        background-color: #974558
        color: #fff
        cursor: pointer
  .col-md-4.col-sm-12
    @media #{$W768}
      margin-top: 1rem

#map
  widht: 100%
  height: 25rem

.modal-content
  padding: 1rem 0
  border: 0
  border-radius: 0
  box-shadow: 0px 0px 30px 0px #dedede
  .modal-header
    justify-content: center
    border: 0
    h5.modal-title
      color: #511826
      font: 1.8rem $cursive
  .modal-body
    form
      +flex-parent(100%, column nowrap, center, center)
      .form-group
        width: 80%
        input, textarea
          border: 0
          border-radius: 0
          text-align: center
          height: 2.5rem
          font-size: 0.7rem
          box-shadow: inset 0px 0px 30px 0px #dedede
        textarea
          height: 7rem
      .btn-primary
        width: 80%
        background-color: #fff
        color: #974558
        font-size: 0.7rem
        height: 2.25rem
        border: 2px solid #974558
        border-radius: 0
        text-transform: uppercase
        &:hover
          cursor: pointer
          background-color: #974558
          color: #fff
