@media #{$W768}
  .navbar-toggleable-md .navbar-nav .dropdown-menu
    position: absolute
    overflow-y: scroll
    max-height: 30rem
    
.menu-large
  position: static !important
.megamenu
  padding: 1rem
  width: 100%
  @media #{$W768}
    .dropdown-header
        padding: 3px 15px !important
  @media #{$W768}
    margin-left: 0
    margin-right: 0
    
.megamenu li
  @media #{$W768}
    margin-bottom: 30px
  &:last-child
    @media #{$W768}
        margin-bottom: 0

.megamenu > div > li > ul
  padding: 0
  margin: 0

.megamenu > div > li > ul > li
  list-style: none

.megamenu > div > li > ul > li > a
  display: block
  padding: 5px 10px
  clear: both
  font-weight: normal
  color: $color--medium-pink
  white-space: normal
  font-family: $sans-serif
  &:focus,
  &:hover
    text-decoration: none

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus
  color: #999999

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus
  text-decoration: none
  background-color: transparent
  background-image: none
  cursor: not-allowed

.megamenu
  .dropdown-header
    padding: 1rem 0
    @media #{$W575}
      padding: 0 !important
      margin: 0
    a
      font-size: 1.33rem
      color: $color--medium-pink
      font-family: $cursive 

  .dropdown-menu
    margin-top: -2px
    border: 0
    border-radius: 0

.dropdown:hover .dropdown-menu
  display: block
  margin-top: -1.5rem
  border: 0
  box-shadow: 0px 0px 30px 0px #dedede

@media #{$W768}
    .navbar-nav .open .dropdown-menu .dropdown-header
        color: #fff
        
.megamenu .dropdown-item
  border-right: 1px solid $color--light-gray
  margin: 0.5rem 0rem
  &:last-child
    border-right: none

.megamenu .dropdown-item:focus, 
.megamenu .dropdown-item:hover
  background-color: transparent
  color: inherit