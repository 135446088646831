.navbar
  font: 1.175rem $cursive
  padding: 1rem 3rem
  justify-content: space-between
  background-color: $color--light-pink
  gap: 1rem
  transition: $transition-25
  @media #{$W575}
    display: flex !important
    align-items: center !important
    gap: 0.5rem
  .navbar-toggler-right
    position: relative
    @media #{$W575}
      position: absolute
  .navbar-wrapper
    overflow-x: hidden
  .navbar-nav
    @media #{$w1024}
      padding: 0 1rem
      display: grid !important
      grid-template-columns: auto auto auto
    @media #{$W768}
      grid-template-columns: auto
  @media #{$W768}
    padding: 0 1rem
    display: grid
    grid-template-columns: auto auto auto
  .nav-item
    .nav-link
      color: $color--chocolate-brown
      transition: $transition-25
      padding: 0.5rem 1.25rem
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      @media #{$w1440}
        padding: 0.5rem 1rem
        font-size: 1rem
      @media #{$w1366}
        padding: 0.5rem .85rem
        font-size: .9rem
        // max-width: 1rem
      border-radius: 35rem
      font-weight: 500
      position: relative
      &:hover
        background-color: $color--white
        color: $color--dark-violet
        padding: 0.5rem 1.25rem
        @media #{$w1440}
          padding: 0.5rem 1rem
        @media #{$w1366}
          padding: 0.5rem .85rem
        font-weight: 600
        display: flex
        justify-content: center
        &:after
          content: ''
          border-width: 10px 10px 0 10px
          border-color: $color--white transparent transparent transparent
          border-style: solid
          position: absolute
          bottom: -9px
    .active
      background-color: $color--white
      color: $color--dark-violet
      padding: 0.5rem 1.25rem
      @media #{$w1440}
        padding: 0.5rem 1rem
      @media #{$w1366}
        padding: 0.5rem .85rem
      font-weight: 600
      display: flex
      justify-content: center

    .nav-offcanvas
      display: flex
      justify-content: center
      align-items: center
      &:hover
        &:after
          content: none

  .navbar-brand
    margin-right: 0
    img
      width: 15rem
      @media #{$w1440}
        width: 12rem
      @media #{$w1366}
        width: 10rem

.navbar-toolbar
  border-left: .125rem solid $color--light-gray
  @media #{$W768}
    border-left: 0
  padding-left: 1rem
  margin-left: -1rem
  .navbar-nav
    display: flex
    justify-content: center
    align-items: center
    gap: 1.5rem
    @media #{$W768}
      grid-template-columns: auto auto auto
    .nav-item
      .nav-link
        display: flex
        padding: 0
        align-items: center
        height: 2.5rem
        &:hover
          background: none
          padding: inherit
          &:after
            content: none
    .nav-item-profile,
    .nav-item-admin
      .nav-link
        margin-bottom: 0.5rem

.sticky-top
  z-index: 10

.form-control-search
  background-color: transparent
  border-top: 0
  border-left: 0
  border-right: 0
  border-bottom: 0
  border-radius: 35rem
  color: $color--white
  width: 2rem
  padding: 1rem
  color: $color--medium-pink
  position: relative
  right: -2rem
  text-indent: -10000px
  transition: $transition-25
  &::placeholder
    color: $color--chocolate-brown
    opacity: 1
    font-style: italic
  &:-ms-input-placeholder
    font-style: italic
    color: $color--chocolate-brown
  &::-ms-input-placeholder
    font-style: italic
    color: $color--chocolate-brown
  &:hover,
  &:focus,
  &.focus,
  &.active,
  &:active
    width: 10rem
    text-indent: 0
    background-color: transparent
    color: $color--dark-violet
    background-color: $color--white

.btn-search
  color: $color--chocolate-brown
  background-color: transparent
  padding: 0
  border-radius: 0
  cursor: pointer
  transition: $transition-25

#header_placeholder
  height: 85px
  width: 100%
  display: none

.navbar-toggler
  top: .95rem
  right: 1rem
  width: 1.5rem
  height: 1rem
  border: 0
  cursor: pointer
  @media #{$W823}
    top: 2.45rem
  @media #{$W768}
    top: .95rem
  span
    display: block
    position: absolute
    height: 2px
    width: 30px
    background: $color--dark-violet
    border-radius: 10px
    opacity: 1
    left: 0
    transform: rotate(0deg)
    transition: $transition-25
    &:nth-child(1)
      top: 0px
    &:nth-child(2)
      top: 9px
    &:nth-child(3)
      top: 18px
  &.open span
    &:nth-child(1)
      top: 9px
      transform: rotate(135deg)
    &:nth-child(2)
      opacity: 0
      left: -60px
    &:nth-child(3)
      top: 9px
      transform: rotate(-135deg)

.nav-small
  font-size: 1.125rem
  padding: 0.3rem 2rem 0.3rem 2rem
  background-color: $color--white
  img
    width: 10rem
    transition: $transition-25
  .form-control-search
    &:hover,
    &:focus,
    &.focus,
    &.active,
    &:active
      color: $color--dark-violet
      background-color: $color--light-pink

  @media #{$W768}
    img
      width: 7rem

.cart-icon
  display: flex
  justify-content: center
  align-items: center
  gap: .5rem
  margin-bottom: 0.33rem
  font-family: $sans-serif
  transition: $transition-25
  .badge
    background-color: $color--strawberry-red
    color: $color--white      
    border-radius: 5rem
    padding: 0.25rem 0.75rem
    font-weight: 800
    line-height: initial
    transition: $transition-25

  &:hover
    .badge
      background-color: $color--dark-violet
      color: $color--white
      &:after
        content: none

  @media #{$W823}
    top: 3rem
    right: 3rem
  @media #{$W768}
    top: 1.5rem
